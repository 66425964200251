.owner-side-main {
  height: 100vh;
  background-color: #10481a;
  /* width: fit-content; */
  padding: 10px;
  position: absolute;
  z-index: 10;
}

.menu {
  color: white;
  font-size: 25px;
  padding-left: 10px;
  cursor: pointer;
}

.side-menu-bar {
  display: flex;
  flex-direction: column;
}

.side-menu-bar-dec {
  margin-top: 20px;
  text-decoration: none;
  color: white;
  text-align: center;
}
.menu-icon {
  font-size: 25px;
  margin-bottom: 5px;
}

.side-menu-bar-one {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 17px;
}

.side-menu-bar-one:hover {
  background-color: #76a17e;
}

.side-menu-bar-dec.active .side-menu-bar-one {
  background-color: #76a17e;
}

.side-menu-bar-dropdrop {
  display: none;
  padding: 0;
}

.dropdown.active .side-menu-bar-dropdrop {
  display: block;
}

.owner-dropdown-item {
  color: white;
  display: block;
  padding: 5px 40px;
  text-decoration: none;
  border-radius: 4px;
  font-size: 16px;
}

.owner-dropdown-item:hover {
  background-color: #4a7c5c;
  color: white;
}

.owner-dropdown-item.active {
  background-color: #76a17e;
  color: white;
}

.arrow-icon {
  font-size: 20px;
}

@media (min-width: 300px) and (max-width: 322px) {
  .menu {
    color: white;
    font-size: 19px;
    padding-left: 10px;
    /* width: 30px; */
  }

  .arrow-icon {
    font-size: 19px;
  }

  .side-menu-bar-one {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 7px;
    cursor: pointer;
    border-radius: 5px;
  }
}
@media (min-width: 300px) and (max-width: 991px) {
  .owner-side-main {
    display: none;
  }
}
