@import "../../sass/variable.scss";
.blog {
  border: 1px solid rgba(128, 128, 128, 0.427);
  margin: 2rem 0;
  // padding-left: 23px;
  border-radius: 10px;
  padding: 0 15px;
}

.blog-img img {
  height: 12rem;
  width: 100%;
  // width: 25rem;
  padding-top: 15px;
  padding-bottom: 15px;
  // border-radius: 3rem;
  // padding-right: 20px;
}
.social-icons-blog i{
color: #000!important;
}
.blog-img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.viewmore {
  margin-bottom: 10px;
}
.social-icons-blog {
  display: flex;
  gap: 15px;
  padding-bottom: 15px;
  justify-content: center;
}
.blog p {
  color: $para-color;
  padding-right: 23px;
}
.social-icons-blog i {
  font-size: 25px;
}
// latest blog
.latestbtn {
  padding: 11px 20px;
  background: none;
  border: none;
  color: #495057;
  font-weight: 600;
  font-size: 18px;
}

.bloglatestsec {
  border: 1px solid rgba(128, 128, 128, 0.427);
  margin: 2rem 0;
  height: 100%;
}
.latestbtn:hover {
  background-color: #e2e6ea;
}

.latestbtn.active {
  color: #61ce70;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.popular-sec-img img {
  width: 100%;
}
.date-sec p {
  color: $para-color;
}
.blogdata {
  height: 9rem;
  overflow: scroll;
  scroll-behavior: smooth;
}

/* Hide scrollbar for Webkit browsers (e.g., Chrome, Safari) */
.blogdata::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.blogdata {
  scrollbar-width: none;
}
