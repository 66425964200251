.admin-side-main {
  height: 100vh;
  background-color: #10481a;
  width: fit-content;
  padding: 10px;
  position: absolute;
  z-index: 10;
  overflow-y: scroll;
  scrollbar-width: none;
}

.admin-menu {
  color: white;
  font-size: 25px;
  padding-left: 10px;
  cursor: pointer;
}

.admin-side-menubar {
  display: flex;
  flex-direction: column;
}

.admin-side-menubar-dec {
  /* margin-top: 20px; */
  text-decoration: none;
  color: white;
  text-align: center;
}
.admin-menu-icon {
  font-size: 27px;
  margin-bottom: 5px;
}

.admin-side-menubar-one {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 17px;
  margin-top: 20px;
}

.admin-side-menubar-one:hover {
  background-color: #76a17e;
}

.admin-side-menubar-dec.active .admin-side-menubar-one {
  background-color: #76a17e;
}

.admin-side-menubar-dropdrop {
  display: none;
  padding: 0;
}

.dropdown.active .admin-side-menubar-dropdrop {
  display: block;
}

.admin-dropdown-item {
  color: white;
  display: block;
  padding: 5px 40px;
  text-decoration: none;
  border-radius: 4px;
  font-size: 16px;
}

.admin-dropdown-item:hover {
  background-color: #4a7c5c;
  color: white;
}

.admin-dropdown-item.active {
  background-color: #76a17e;
  color: white;
}

.admin-arrow-icon {
  font-size: 20px;
}

@media (min-width: 300px) and (max-width: 322px) {
  .admin-menu {
    color: white;
    font-size: 19px;
    padding-left: 10px;
    /* width: 30px; */
  }

  .admin-arrow-icon {
    font-size: 19px;
  }

  .admin-side-menubar-one {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 7px;
    cursor: pointer;
    border-radius: 5px;
  }
}
@media (min-width: 300px) and (max-width: 991px) {
  .admin-side-main {
    display: none;
  }
}


