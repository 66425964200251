.brand-img {
  background-color: #275438;
  text-align: center;
  padding: 15px;
}

.review-heading {
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  margin-top: 35px;
}
.all-font {
  font-size: 19px;
  font-weight: 500;
  margin-top: 40px;
}
.overall {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.overall-exp {
  font-size: 17px;
  font-weight: 600;
  width: 165px;
}

.title-label {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
}

.title-input {
  margin-top: 10px;
  width: 55%;
  border: 2px solid rgb(155, 152, 152);
  border-radius: 4px;
  padding: 12px 10px;
  transition: border-color 0.3s;
}

.title-input:focus {
  border-color: green;
  outline: none;
  box-shadow: none;
}
.title-input:hover {
  border-color: green;
  outline: none;
  box-shadow: none;
}

.text-area {
  padding: 12px 10px;
  width: 70%;
  border: 2px solid rgb(155, 152, 152);
  border-radius: 4px;
  transition: border-color 0.3s;
}
.text-area:focus {
  border-color: green;
  outline: none;
  box-shadow: none;
}
.text-area:hover {
  border-color: green;
  outline: none;
  box-shadow: none;
}
.text-area-required {
  margin-top: 50px;
  padding: 10px;
  width: 25%;
  font-size: 13px;
  font-weight: 400;
}

.text-area-data {
  display: flex;
}

.rating {
  margin-left: 50px;
  display: inline-block;
}

.star {
  margin: 5px;
  padding: 1px 10px;
  font-size: 1.7rem;
  cursor: pointer;
  color: rgb(58, 57, 57);
  transition: color 0.3s;
  background-color: rgb(248, 250, 249);
}

.star.filled {
  color: gold;
}
.minimum-character {
  font-size: 14px;
  color: #757474;
}
.text-area-required-color {
  color: blue;
}
.term-check {
  display: flex;
  align-items: center;
}
.tick-checkbox {
  width: 20px;
  height: 17px;
}
.tick-checkbox:focus {
  border-color: green;
  outline: none;
  box-shadow: none;
}
.btns {
  width: 150px;
  background-color: #275438;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btns:hover {
  background-color: #15502c;
}
.field-error {
  color: red;
  margin-top: 5px;
  font-size: 13px;
}
/* Mobile  */
@media (min-width: 10px) and (max-width: 576px) {
  .review-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
  }
  .review-insights-experiences {
    font-size: 12px;
  }
  .all-font {
    font-size: 19px;
    font-weight: 500;
    margin-top: 40px;
  }
  .overall {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .overall-exp {
    width: 145px;
    font-size: 17px;
    font-weight: 600;
  }
  .rating {
    margin: 10px 0px;
    display: inline-block;
  }
  .title-input {
    margin-top: 10px;
    width: 100%;
    border: 2px solid rgb(155, 152, 152);
    border-radius: 4px;
    padding: 12px 10px;
    transition: border-color 0.3s;
  }
  .text-area {
    padding: 12px 10px;
    width: 100%;
    border: 2px solid rgb(155, 152, 152);
    border-radius: 4px;
    transition: border-color 0.3s;
  }

  .text-area-required {
    width: 100%;
    font-size: 13px;
    font-weight: 400;
  }

  .text-area-data {
    display: flex;
    flex-direction: column;
  }
  .term-check {
    /* display: flex; */
  }
  .tick-checkbox {
    width: 20px;
    height: 20px;
  }
  .terms-agree {
    font-size: 10px;
  }
}
/* Tablet  */
@media (min-width: 577px) and (max-width: 768px) {
  .review-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
  }
  .review-insights-experiences {
    font-size: 12px;
  }
  .all-font {
    font-size: 19px;
    font-weight: 500;
    margin-top: 40px;
  }
  .overall {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .overall-exp {
    width: 170px;
    font-size: 17px;
    font-weight: 600;
  }
  .rating {
    margin: 10px 0px;
    display: inline-block;
  }
  .title-input {
    margin-top: 10px;
    width: 100%;
    border: 2px solid rgb(155, 152, 152);
    border-radius: 4px;
    padding: 12px 10px;
    transition: border-color 0.3s;
  }
  .text-area {
    padding: 12px 10px;
    width: 100%;
    border: 2px solid rgb(155, 152, 152);
    border-radius: 4px;
    transition: border-color 0.3s;
  }

  .text-area-required {
    width: 80%;
    font-size: 13px;
    font-weight: 400;
  }

  .text-area-data {
    display: flex;
    flex-direction: column;
  }
  .term-check {
    /* display: flex; */
  }
  .tick-checkbox {
    width: 20px;
    height: 20px;
  }
  .terms-agree {
    font-size: 15px;
  }
}
/* Small Laptop  */
@media (min-width: 769px) and (max-width: 1025px) {
  .review-heading {
    font-size: 40px;
    font-weight: 600;
    line-height: normal;
  }
  .review-insights-experiences {
    font-size: 19px;
  }
  .all-font {
    font-size: 19px;
    font-weight: 500;
    margin-top: 40px;
  }
  .overall {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .overall-exp {
    width: 170px;
    font-size: 17px;
    font-weight: 600;
  }
  .rating {
    margin: 10px 0px;
    display: inline-block;
  }
  .title-input {
    margin-top: 10px;
    width: 60%;
    border: 2px solid rgb(155, 152, 152);
    border-radius: 4px;
    padding: 12px 10px;
    transition: border-color 0.3s;
  }
  .text-area {
    padding: 12px 10px;
    width: 100%;
    border: 2px solid rgb(155, 152, 152);
    border-radius: 4px;
    transition: border-color 0.3s;
  }

  .text-area-required {
    width: 40%;
    font-size: 13px;
    font-weight: 400;
  }

  .text-area-data {
    display: flex;
  }
  .term-check {
    /* display: flex; */
  }
  .tick-checkbox {
    width: 20px;
    height: 20px;
  }
  .terms-agree {
    font-size: 15px;
  }
}
