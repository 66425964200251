* {
  scroll-behavior: smooth;
}
.btnsall {
  color: #ffffff;
  background-color: #61ce70;
  border-color: rgb(97, 206, 112);
  border-radius: 5px;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 6px;
  width: 4rem;
}
.desktopViewNotSupport{
  margin-top: 8rem;
}
.btn-flex{
  display: flex;
  gap: 20px;
}
.nav-tabs .nav-link.active {
  color: #61ce70 !important;
  font-weight: 700;
}
/* Style the placeholder text */
::placeholder {
  color: #f8f9fa;  /* Change placeholder text color */
  font-size: 16px;  /* Adjust the font size */
  opacity: 1;  /* Ensure full opacity of the placeholder */
}
.btn-success {
  background-color: #61ce70;
  font-weight: bold;
  border-color: #61ce70;
  border-radius: 5px;
  text-decoration: none;
  color: #ffffff;
}
.button-next {
  padding-left: 60px;
  padding-right: 60px;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.navbar-toggler {
  background-color: #fff !important;
}
.error {
  color: red;
  text-align: left;
}
::placeholder{
  color: gray;
  font-size: 15px;
}
.modalheader{
border-bottom: none!important;
}
@media (min-width: 300px) and (max-width: 768px) {
  .aos-animate {
    overflow: hidden; /* Hide overflow to prevent scrollbars */
  }
  .orderone {
    order: 1;
  }
  .ordertwo {
    order: 2;
  }
  .displaynone {
    display: none;
  }
  .navbar-toggler {
    width: 4rem !important;
  }
}
@media (min-width: 700px) and (max-width: 769px) {
  .orderonetab {
    order: 1;
  }
  .ordertwotab {
    order: 2;
  }
}
@media(min-width:318px) and (max-width:321px){
  .declinebtn{
    margin-top: 10px;
  }
}
.offcanvasbtn{
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}