.policy-heading h1{
    font-size: 35px;
    font-family: Georgia;
    font-weight: bolder;
    color: #3689A1;
    text-align: center;
}
.policy-hr{
    border-top: 4px solid;
    color: #10481a;

}
.policy-para{
    color: #68717B;
}
.privacy-h4{
    font-weight: 600;
}
.policy-heading h4{
    font-weight: 600;
}
.policy-heading p{
    color: #68717B;
}
.policy-link{
    color: #61CE70;
    text-decoration: none;
}