.families {
  background: url(https://res.cloudinary.com/dbtsrjssc/image/upload/v1720604525/families_background_yzyaaz.jpg);
  background-repeat: no-repeat;
  -o-object-fit: cover;
     object-fit: cover;
  height: auto;
  font-weight: 600;
  background-size: 100% 100%;
  color: #fff;
}

.familybanner h1 {
  text-align: center;
  color: #61ce70;
  padding-top: 5rem;
  font-weight: 900;
  font-size: 3rem;
}

.family-section-2 {
  padding-top: 5rem;
  position: relative;
}

.family-section2-img img {
  width: 100%;
  height: 23rem;
  background-size: contain;
  -o-object-position: center;
     object-position: center;
  border-radius: 15px;
}

.quebox1 {
  font-weight: 600;
  padding: 10px 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 42.5rem;
  text-align: center;
  border-radius: 24px 0px 24px 0;
  color: #fff;
  background-color: #032509;
  background-image: linear-gradient(to right, #032509, #61ce70);
  margin-bottom: 10px;
  font-size: 15px;
}

.quebox2 {
  font-weight: 600;
  padding: 10px 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 40rem;
  text-align: center;
  border-radius: 24px 0px 24px 0;
  color: #fff;
  background-color: #032509;
  background-image: linear-gradient(to right, #032509, #61ce70);
  margin-bottom: 10px;
  font-size: 15px;
}

.rightbox {
  display: flex;
  justify-content: center;
}

.ask-question h1 {
  text-align: center;
  font-weight: 600;
  color: #000;
  text-decoration: underline;
  padding-bottom: 25px;
}

.family-question {
  margin-bottom: 25px;
}

.questwithans {
  background-color: #F4F7F3;
  text-align: center;
}

.faq h2 {
  font-weight: 600;
  font-size: 2.5rem;
}

.accordionbox {
  margin-top: 25px;
}

.accordion-item {
  margin-bottom: 25px;
}

.accordion-button:not(.collapsed) {
  background-color: #fff !important;
  box-shadow: none !important;
  color: #61ce70;
}

.accordion-button:focus {
  box-shadow: none !important;
}/*# sourceMappingURL=Families.css.map */