.bg-white {
  background-color: aqua;
}
.topheading {
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-size: 2rem;
  font-weight: 700;
}
.input-container {
  display: flex;
}

::placeholder {
  color: #757575;
  opacity: 1;
}
.home-name h3 {
  font-weight: 600;
  font-size: 25px;
}
.search-input {
  background: #eeeeee;
  color: #212121;
  vertical-align: middle;
  font-size: 16px;
  line-height: 2;
  padding: 10px 20px;
  border: 0;
  outline: none;
  box-shadow: inset 0 -1px 1px -1px #cccccc, inset 0 1px 1px -1px #cccccc,
    inset 1px 0 1px -1px #cccccc;
  width: 100%;
}

.search-submit {
  background: #61ce70;
  color: #ffffff;
  vertical-align: middle;
  padding: 0 20px;
  border: 0;
  outline: none;
  cursor: pointer;
}

.search-submit:hover {
  background: #61ce70;
}

.search-submit i {
  font-size: 24px;
  margin-top: 9px;
}
.filtersec {
  margin-bottom: 3rem;
}
.filtersec h1 {
  margin-top: 15px;
  font-weight: 600;
  font-size: 2rem;
}
.filtersec h2 {
  margin-top: 8px;
  font-size: 18px;
  font-weight: 600;
}
.hearticon:hover {
  color: #61ce70;
}
.checkboxinput {
  transform: scale(1.5); /* Adjust the scaling factor to your desired size */
  margin: 10px; /* Optional: adjust margin to compensate for the increased size */
}

.checkboxinput[type="checkbox"] {
  -webkit-transform: scale(1.5); /* For older webkit-based browsers */
  -moz-transform: scale(1.5); /* For older Firefox versions */
}

/* house list */
.house-img img {
  width: 100%;
  border-radius: 10px;
}
.list-house {
  border: 1px solid #d0d0d0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  padding: 20px 20px;
  margin-bottom: 30px;
}
.morebtn {
  white-space: nowrap;
  /* width: 12rem; */
  width: 100%;
  padding: 10px 22px;
  border-radius: 25px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border: 2px solid #61ce70;
  background: transparent;
}
.morebtn:after {
  width: 0;
  height: 100%;
  background: #61ce70;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.4s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.morebtn:hover {
  color: whitesmoke;
  background: #61ce70;
}
.morebtn:hover:after {
  width: 100%;
}
.offcanvas-header {
  background-color: #f8f9fa;
}

.offcanvas-body {
  padding: 1rem;
}
.offcanvasfilter {
  border: 2px solid #61ce70;
  background-color: #61ce70;
  width: 9rem;
  margin-bottom: 20px;
  margin-left: 15px;
}

.morebtn {
  color: #0e0e16; /* Text color */
  border: 2px solid #61ce70; /* Border color */
  padding: 10px 20px; /* Padding for the button */
  text-transform: uppercase; /* Text transformation */
  font-size: 1em; /* Font size */
  font-weight: bold; /* Font weight */
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94); /* Smooth transition */
  position: relative; /* Relative position for the pseudo-element */
  overflow: hidden; /* To hide the expanding background */
  display: inline-block; /* Inline block for padding */
  z-index: 1; /* Z-index for stacking context */
}

/* Hover Effect 1: Background expansion */
.morebtn::after {
  content: ""; /* Empty content for the pseudo-element */
  position: absolute; /* Absolute positioning */
  width: 0; /* Initial width */
  height: 100%; /* Full height */
  top: 0; /* Align at the top */
  left: 0; /* Align at the left */
  background: #61ce70; /* Background color */
  z-index: -1; /* Place it behind the button text */
  transition: width 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94); /* Transition for expansion */
}

.morebtn:hover::after {
  width: 100%; /* Expand to full width on hover */
}

/* Hover Effect 2: Text color change */
.morebtn:hover {
  color: #ffffff; /* Change text color on hover */
}
.heartbtn {
  font-size: 29px;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: #fff;
  /* background: transparent; */
  background-color: #75757582;
  border: none;
  border-radius: 50%;
}
.hearticon {
  margin-top: 5px;
  padding: 3px;
  font-size: 30px;
  /* position: absolute;
    right: 35rem;
    top: 37rem; */
  /* color:#61ce70; */
}
.house-img {
  position: relative;
}

.reviewbtn {
  padding: 0px 8px;
  padding: 0px 8px;
  border-radius: 8px;
  background: #61ce70;
  color: #fff;
  text-align: center;
  font-weight: 700;
  width: 40px;
  margin-bottom: 0rem !important;
  height: 25px;
}
.reviewbtn p {
  margin-bottom: 0rem !important;
}
.reviews {
  display: flex;
  gap: 15px;
  margin-top: 12px;
}
.reviewheading {
  color: #333;
  font-weight: 700;
}
.reviewheading span {
  color: #61ce70;
  font-weight: 500;
}
.servies-provided {
  display: flex;
  gap: 40px;
  font-size: 24px;
  margin-top: 8px;
  margin-bottom: 10px;
}
.empty-data {
  text-align: center;
}
@media (min-width: 700px) and (max-width: 769px) {
  .topheading {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .house-img img {
    width: 100%;
    height: 12rem;
    object-fit: fill;
    background-size: cover;
    margin-top: 24px;
  }
  .familybanner h1 {
    font-size: 2rem;
    padding-top: 0rem;
  }
}
@media(min-width:300px) and (max-width:500px){
  .home-name h3 {
    font-weight: 600;
    font-size: 20px;
    margin-top: 15px;
}
.reviews{
  gap: 5px;
}
}