@import '../../sass/variable.scss';

.listinghomebtn{
    background-color: $button-color;
    margin-bottom: 20px;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
}
.provider-btn{
    text-align: center;
    // margin-bottom: 20px;
}
.provider-img1{
  width: 100%;
}
.provider-img2 img{
    width: 100%;
}