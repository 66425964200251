@import "../sass/variable.scss";
/* Navbar */

body {
  /* font-family: Nunito Sans; */
  font-family: "Anton SC", sans-serif;
}
.navitems {
  gap: 3rem;
}
.navlinks {
  font-size: 18px;
  color: #fff !important;
}
.navlinks:active,
.nav-tabs:active {
  color: $button-color !important;
}
.navlogo img {
  width: 9rem;
}
.bannertopnav{
  background: #084D24;
  box-shadow: 0 8px 32px 0 transparent;
  backdrop-filter: blur( 0px );
  -webkit-backdrop-filter: blur( 0px );
  // border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  z-index: 1;
  position: relative;
  display: flex;
  gap: 25px;
  justify-content: space-around;
}
.topcontact-no{
  color: #fff;
text-align: left!important;
}
.topcontact-no p{
  padding-top: 8px;
}
.contactemail{

}
.contactemail p{
  padding-top: 8px;
  
}
.maindiv{
  background: url("https://res.cloudinary.com/dbtsrjssc/image/upload/v1719832342/afhbanner_u5uwh8.jpg");
  background-repeat: no-repeat;
  /* background-size: cover; */
  object-fit: cover;
  height: auto;
  font-weight: 600;
  background-size: 100% 100%;
  /* background-attachment: fixed;  */
}

// <Route path="/home/blog/:title" element={<BlogLandingPage />} />
.realtiveoverlay {
  position: relative;
  width: 100%;
  height: auto;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 0;
  background: rgb(3, 37, 9);
  // background: linear-gradient(
  //   123deg,
  //   rgba(3, 37, 9, 1) 37%,
  //   rgba(251, 251, 253, 0.49352240896358546) 96%
  // );
  background: linear-gradient(
    123deg,
    #1F5B35 37%,
    rgba(251, 251, 253, 0.49352240896358546) 96%
  );
  pointer-events: none;
}
/* banner */
.banheading {
  position: relative;
  z-index: 1;
  color: #fff;
}
.banheading h2 {
  font-size: 20px;
  color: #fff;
}
.banheading h1 {
  font-size: 5rem;
  color: #fff;
}
.checked {
  color: $button-color;
}
.spanspecial {
  font-family: "Readex Pro", sans-serif;
}
/* .fancy {
  
  @supports (background-clip: text) or (-webkit-background-clip: text) {
    background-image: url("data:image/svg+xml,%3Csvg width='2250' height='900' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%2300A080' d='M0 0h2255v899H0z'/%3E%3Ccircle cx='366' cy='207' r='366' fill='%2300FDCF'/%3E%3Ccircle cx='1777.5' cy='318.5' r='477.5' fill='%2300FDCF'/%3E%3Ccircle cx='1215' cy='737' r='366' fill='%23008060'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 110% auto;
    background-position: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
} */
.stars {
  line-height: 3;
}
/* search bar */
.banmainhead{
  font-size: 4.5rem!important;
    line-height: 1.3;
}
.bansearch input[type="search"] {
  -webkit-appearance: none !important;
  background-clip: padding-box;
  background-color: white;
  vertical-align: middle;
  border-radius: 0.25rem;
  border: 1px solid #e0e0e5;
  font-size: 1rem;
  width: 100%;
  line-height: 2;
  padding: 0.375rem 1.25rem;
  -webkit-transition: border-color 0.2s;
  -moz-transition: border-color 0.2s;
  transition: border-color 0.2s;
}

.bansearch input[type="search"]:focus {
  transition: all 0.5s;
  /* box-shadow: 0 0 40px #f9d442b9; */
  border-color: #008060;
  outline: none;
}

form.search-form {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.lablesearch {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  align-self: center;
  margin-bottom: 0;
}

.bansearch input.search-field {
  margin-bottom: 0;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  align-self: center;
  height: 51px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bansearch input.search-submit {
  height: 51px;
  margin: 0;
  padding: 1rem 1.3rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  /* font-family: "Font Awesome 5 Free"; */
  font-size: 1rem;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.buttonsearch {
  display: inline-block;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.15;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background: $button-color;
  color: #fff;
  border: 1px solid transparent;
  vertical-align: middle;
  text-shadow: none;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.buttonsearch:hover,
.buttonsearch:active,
.buttonsearch:focus {
  cursor: pointer;
  // background: #d4b743;
  // color: #292826;
  outline: 0;
}

/* steps */
.stepstar {
  font-size: 1.5rem;
}
.headingsteps {
  font-weight: 600;
  font-size: 3rem;
}
.stepsimg {
  position: relative;
}
.stepsimg img {
  border-radius: 15px;
}
.steps-data {
  padding: 15px 5px;
}
.steps-heading {
  margin-top: 5rem;
  margin-bottom: 2rem;
}
.stepsimg2 {
  border: 8px solid #fff;
  position: absolute;
  border-radius: 15px;
  top: 12rem;
  right: 0px;
}
.step-data-img img {
  width: 4rem;
}
.step1 {
  gap: 15px;
}
.step-data-content h3 {
  font-weight: 600;
}
.step-data-content p {
  color: gray;
}

/* benefits */
.section-3 {
  position: relative;
  background-color: #0a4e26;
  /* margin-bottom: 5rem; */
}
.sectio-3-background img {
  /* background: url("https://res.cloudinary.com/dbtsrjssc/image/upload/v1719922877/sect-3-afh_heaqac.jpg"); */
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: auto;
  font-weight: 600;
  background-size: 100% 100%;
  height: 44rem;
}
.overlay-section3 {
  position: absolute;
  left: 90px;
  top: 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(8, 77, 36, 0.9);
  z-index: 1;
  transform: skewX(-14deg);
  -webkit-transform: skewX(-14deg);
}
.section-3text {
  position: relative;
  z-index: 1;
  color: #fff;
}
.benefit1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.number {
  font-style: italic;
  color: #fff;
  font-weight: 800;
  font-size: 70px;
}
.benefit1 span {
  font-weight: 300;
  font-size: 23px;
}
/* section-4 */
.section-4 {
  background: url("https://res.cloudinary.com/dbtsrjssc/image/upload/v1719919729/section3-afh_j0s5ky.png");
  background-repeat: no-repeat;
  object-fit: cover;
  height: auto;
  font-weight: 600;
  background-size: 100% 100%;
}
.section-4img img {
  /* height: 5rem; */
  width: 90%;
  object-fit: cover;
  background-size: contain;
  border: 8px solid #0a4e26;
  border-radius: 15px;
}
.section-4text h1 {
  font-weight: 800;
  font-size: 27px;
}
.section-4text p {
  color: #808080b8;
  font-size: medium;
}
/* contact us bnt*/
.contactus-btn {
  background: url("https://res.cloudinary.com/dbtsrjssc/image/upload/v1719919729/section3-afh_j0s5ky.png");
  background-repeat: no-repeat;
  object-fit: cover;
  height: auto;
  font-weight: 600;
  background-size: 100% 100%;
  background-color: #19291b;
  color: #fff;
  padding-bottom: 25px;
}
.contactus-heading {
  padding-top: 5rem;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactus-heading h1 {
  color: #ffffff;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 43px;
  font-size: 65px;
  font-weight: 600;
  line-height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactusbtn button {
  border-radius: 6px 6px 6px 6px;
  padding: 21px 40px 19px 40px;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: $button-color;
  color: #fff;
}
.contactusbtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.medicaidbtn {
  background: $button-color;
  text-decoration: none;
  color: #fff;
  padding: 9px 7px;
}
/* medicaid */
.medicaid {
  padding-top: 35px;
  padding-bottom: 35px;
}
.medicaid_head {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionline {
  border-top: 5px solid #084d24;
}
.medicaid-img img {
  width: 80%;
  border: 10px solid #084d24;
  border-radius: 2rem;
}
.medicaid_head h1 {
  font-weight: 600;
}
/* footer */

.footer {
  background-color: #0c160f;
  color: #fff;
  padding-top: 5rem;
}
.footer-head h1 {
  /* font-family: Readex Pro; */
  font-weight: 600;
  font-size: 35px;
  line-height: 52px;
  letter-spacing: -1px;
  color: #c5c5c5;
  padding-top: 20px;
}
.footerul {
  font-size: 25px;
}
.uldata li {
  list-style: none;
  color: #c5c5c5;
  text-decoration: none;
  font-weight: 600;
}
.ulLink:hover {
  color: $button-color;
}
.ulLink {
  list-style: none;
  color: #c5c5c5;
  text-decoration: none;
  font-size: 15px;
}
.footer-social {
  gap: 25px;
  font-size: 27px;
}
.footer-social i {
  color: #fff;
}
.footer-social i:hover {
  color: $button-color;
}
.footer-head img {
  width: 11rem;
}
.nav-link.active {
  color: $button-color;
}
.bannerques{
  font-size: 36px!important;
  color: #61ce70!important;
}
@media (min-width: 300px) and (max-width: 500px) {
  .steps-heading {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .banmainhead {
    font-size: 3.5rem !important;
    line-height: 1.3;
}
.bannertopnav{
  display: block;
}
.phnno-btn {
  font-size: 14px;
}
.topcontact-no{
  text-align: center!important;
}
.contactemail{
  margin-top: -1rem;
}
.bannerques {
  font-size: 27px !important;
  color: #61ce70 !important;
}
}
