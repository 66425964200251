/* small mobiles */
@media (min-width: 310px) and (max-width: 322px) {
  .banheading h1 {
    font-size: 2.3rem;
    margin: 15px 0px;
  }
  /* .sign-btn {
    margin-right: 53px !important;
  } */
  .bansearch input[type="search"] {
    padding: 0.375rem 0.5rem;
  }
  .lablesearch {
    /* flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    align-self: center;
    margin-bottom: 0; */
    width: 40%;
  }

  .headingsteps {
    margin-top: 10px;
    font-size: 22px;
  }
  .stepsimg img {
    width: 100%;
    border-radius: 15px;
  }
  .steps-heading {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .stepsimg2 {
    margin-top: 7px;
    width: 15rem;
  }
  .overlay-section3 {
    position: absolute;
    left: -100px;
    top: 37rem;
    width: 50%;
    height: 30%;
    background-color: rgba(8, 77, 36, 0.9);
    z-index: 1;
    transform: skewX(-14deg);
    -webkit-transform: skewX(-14deg);
  }
  .step-data-content p {
    font-size: 12px;
  }
  .section-3 {
    margin-top: 10rem;
  }
  .benefit1 span {
    font-weight: 300;
    font-size: 15px;
  }
  .sectio-3-background img {
    object-fit: contain;
    height: 15rem;
  }
  .contactus-heading h1 {
    color: #ffffff;
    margin: 0px;
    padding: 0px;
    /* margin-bottom: 0px; */
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .medicaidbtn {
    font-size: 10.6px;
  }
  .section-4text h1 {
    font-size: 19px;
  }
  .section-4text p {
    font-size: 10px;
  }
  .medicaid-data p {
    font-size: 10px;
  }
  .medicaid_head {
    font-size: 10px;
  }
  .medicaid-text p {
    font-size: 10px;
  }
  .footer-head h1 {
    font-size: 28px;
  }
  .footerul {
    font-size: 20px;
  }
  .footer-social {
    margin-bottom: 20px;
  }

  /* families */
  .familybanner h1 {
    text-align: center;
    color: #61ce70;
    padding-top: 5rem;
    font-weight: 900;
    font-size: 1.8rem !important;
  }

  .family-content {
    padding: 15px;
  }
  .family-content p {
    font-size: 13px;
  }
  .quebox1,
  .quebox2 {
    width: 100% !important;
    border-radius: 0px !important;
  }
  .offset-2 {
    margin-left: 0px;
    text-align: center;
    /* margin-left: 8.666667% !important; */
  }
  .faq h2 {
    font-size: 1.5rem !important;
  }

  /* medicaid */
  .familybanner p {
    margin-top: 20px;
  }
  .familybanner p {
    margin-top: 10px;
    font-size: 13px;
    padding-bottom: 2.5rem;
  }
  .stars {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 10px;
  }
  .medicaid-content p {
    font-size: 14px;
  }
  /* blog  */
  .latestbtn {
    padding: 11px 10px !important;
    font-size: 15px;
  }
  .popular-sec-content p {
    font-size: 11px;
  }
  .blog-tittle {
    font-size: 18px !important;
    font-weight: 600;
  }
  .blog p {
    font-size: 12px !important;
  }
  .blog-section h2 {
    font-size: 17px !important;
    margin-bottom: 10px;
  }
  .blog-section p {
    font-size: 10px;
  }
  .blog-comment input {
    margin-bottom: 10px;
  }
  /* bloglandingpage  */
  .landing-blog-email {
    margin-bottom: 20px;
  }
  /* provider  */
  .provider-content {
    font-size: 11px;
  }
  /* contact  */

  .contact-section-info p {
    font-size: 15px !important;
  }
  .contact-section h2 {
    font-size: 22px;
    padding-bottom: 5px !important;
  }
  .contact-form {
    font-size: 14px;
  }
  .contact-btn {
    font-size: 15px;
  }
  .recaptch {
    padding-right: 700px;
  }

  /* seniorcare home list */
  .topheading {
    margin-top: 1.8rem !important;
    margin-bottom: 1.5rem !important;
    font-size: 1.3rem !important;
    font-weight: 700;
  }
  .home-name h3 {
    margin-top: 20px;
    font-size: 21px !important;
  }
  .reviewbtn {
    width: 35px !important;
  }
  .reviewheading {
    font-size: 13px;
    margin-top: 4px;
  }
  .heartbtn {
    right: 0.5rem !important;
    top: 0.5rem !important;
    font-size: 20px !important;
  }
  .hearticon {
    font-size: 19px !important;
  }
  .servies-provided {
    margin-top: 0px !important;
  }

  /* list home landing page  */
  .landingheading {
    font-size: 20px !important;
    margin-top: 1.5rem !important;
  }
  .list-home-sec p {
    font-size: 13px !important;
    margin-bottom: 0px;
  }
  .addreviewslink {
    font-size: 13px;
  }
  .home-service-activities {
    height: 20rem;
    overflow: scroll;
    scroll-behavior: smooth;
    padding: 0px;
    margin-top: 8px;
    cursor: pointer;
  }
  .home-services-list {
    padding-left: 22px;
  }

  .buttonssimilar {
    font-size: 10px !important;
  }
  .recommended-house {
    padding: 22px 20px !important;
    margin-left: 0px !important;
  }
  .frame {
    margin-right: 25px !important;
  }
  .house-name-link {
    font-size: 22px !important;
  }
  .house-address-link {
    font-size: 10px !important;
  }
  .house-desp-recommended {
    /* margin: 10px 0px; */
    height: 8.5rem !important;
    overflow: scroll;
    scroll-behavior: smooth;
  }
  .house-desp-recommended p {
    font-size: 13px;
    text-align: start;
  }
}
/* mobile  */
@media (min-width: 323px) and (max-width: 500px) {
  .overlay-section3 {
    position: relative;
  }
  .benefit1 span {
    font-weight: 300;
    font-size: 16px;
  }
  .banheading h1 {
    font-size: 3rem;
    margin: 15px 0px;
  }
  .medicaidbtn {
    font-size: 10.6px;
  }
  .contactus-heading h1 {
    color: #ffffff;
    margin: 0px;
    padding: 0px;
    /* margin-bottom: 0px; */
    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .medicaid_head h1 {
    font-size: 19px;
    font-weight: 600;
  }
  .bansearch input[type="search"] {
    padding: 0.375rem 0.4rem;
  }
  .familybanner h1 {
    padding-top: 0rem !important;
    font-weight: 900;
    font-size: 2rem !important;
  }
  .familybanner p {
    margin-top: 10px;
    font-size: 13px;
    padding-bottom: 2.5rem;
  }

  .stepsimg img {
    width: 100%;
    border-radius: 15px;
  }
  .home-name h3 {
    font-weight: 600;
    font-size: 18px !important;
    margin-top: 17px !important;
  }
  .reviews {
    gap: 14px;
  }
  .section-3 {
    margin-top: 9rem;
    padding-bottom: 2rem;
  }
  .recommended-house {
    margin-left: 0 !important;
  }
  .sectio-3-background img {
    object-fit: contain;
    height: 15rem;
  }

  .navlinks {
    color: black !important;
  }

  .navitems {
    display: block;
  }
/* 
  .sign-btn {
    margin-right: 53px !important;
  } */

  .quebox1,
  .quebox2 {
    width: 100% !important;
    border-radius: 0px !important;
  }

  .rightbox {
    display: flex;
    justify-content: left !important;
  }
  .family-content {
    font-size: 13px;
    margin-top: 35px;
    padding: 10px;
  }
  .faq h2 {
    font-size: 1.5rem !important;
  }

  .latestbtn {
    padding: 11px 14px;
  }
  .offset-2 {
    margin-left: 0px;
    text-align: center;
    /* margin-left: 8.666667% !important; */
  }
  .heading-title {
    margin: 15px 0px 20px 0px;
    font-size: 40px;
  }

  .headingsteps {
    margin-top: 10px;
    font-size: 30px;
  }
  .steps-heading {
    margin-top: 0rem;
    margin-bottom: 2rem;
  }
  .stepsimg2 {
    margin-top: 40px;
    width: 18rem;
  }

  .benefit1 {
    margin-top: 20px;
  }
  .sectio-3-background {
    margin-top: 60px;
  }
  .overlay-section3 {
    position: absolute;
    left: -120px;
    top: 39rem;
    width: 50%;
    height: 40%;
    background-color: rgba(8, 77, 36, 0.9);
    z-index: 1;
    transform: skewX(-14deg);
    -webkit-transform: skewX(-14deg);
  }
  .section-4text h1 {
    font-size: 20px;
  }
  .section-4text p {
    font-size: 12px;
  }
  .contactusbtn {
    margin-top: 10px;
  }
  .medicaid-data p {
    font-size: 12px;
  }
  .medicaid_head {
    font-size: 10px;
  }
  .medicaid-text p {
    font-size: 12px;
  }
  .medicaid-img,
  .section-4img {
    text-align: center;
  }
  .blog {
    padding: 15px;
  }
  .social-icons-blog {
    display: flex;
    gap: 15px;
    padding-bottom: 15px;
    justify-content: center;
  }
  .contactus-heading h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  /* .viewmore {
    margin-bottom: 10px;
    width: 100%;
  } */

  .blog-tittle {
    font-size: 18px !important;
    font-weight: 600;
  }
  .blog p {
    font-size: 12px;
  }
  .blog-section p {
    font-size: 13px;
  }
  .blog-comment input {
    width: 100%;
    padding: 10px;
    outline: 0;
    border: 1px solid #68717b;
    margin-bottom: 10px;
  }
  .landing-blog-email {
    margin-bottom: 20px;
  }
  .footer-social {
    margin-bottom: 20px;
  }
  .footerul {
    font-size: 20px;
  }
  .footer-head h1 {
    font-size: 30px;
    line-height: 1.5;
  }
  /* medicaid */

  .medicaid-sec3 img {
    width: 90% !important;

    border: 10px solid #084d24 !important;
  }
  .medicaid-sec3 {
    text-align: center;
    padding: 15px;
  }

  .medicaid-content {
    font-size: 13px;
  }
  .medicaid-content h1 {
    font-size: 23px;
  }
  .medicaid-content h6 {
    font-size: 12px;
  }
  .howtoapply-medicaid {
    font-size: 13px;
  }

  .apply-person-mail {
    font-size: 13px;
  }
  .apply-online {
    font-size: 13px;
  }
  .medicaid-sec3-text {
    font-size: 13px;
  }
  .medicaid-sec3-text h3 {
    font-size: 20px;
  }
  .stars {
    font-size: 15px;
    line-height: 2;
    margin-bottom: 10px;
  }
  .blog-section h2 {
    color: black;
    text-align: center;
    font-weight: 600;
    font-size: 20.5px !important;
    margin-bottom: 10px;
  }
  .popular-sec-content p {
    font-size: 14px;
  }

  /* provider  */
  .provider-content {
    font-size: 13px;
  }
  /* contact  */
  .contact-section-info p {
    font-size: 13px !important;
  }
  .contact-section h2 {
    font-size: 22px;
    padding-bottom: 5px !important;
  }

  /* seniorcare home list */
  .topheading {
    margin-top: 1.8rem !important;
    margin-bottom: 1.5rem !important;
    font-size: 1.8rem !important;
    font-weight: 700;
  }
  .home-name h3 {
    margin-top: 20px;
    font-size: 21px !important;
  }
  .reviewbtn {
    width: 35px !important;
  }
  .reviewheading {
    font-size: 15.6px;
  }
  .heartbtn {
    right: 0.5rem !important;
    top: 0.5rem !important;
    font-size: 22px !important;
  }
  .hearticon {
    font-size: 23px !important;
  }
  .servies-provided {
    margin-top: 0px !important;
  }

  /* list home landing page  */
  .landingheading {
    font-size: 20px !important;
    margin-top: 1.5rem !important;
  }
  .list-home-sec p {
    font-size: 15px !important;
    margin-bottom: 0px;
  }
  .addreviewslink {
    font-size: 15px;
  }
  .frame {
    margin-right: 25px !important;
  }
  .home-service-activities {
    height: 20rem;
    overflow: scroll;
    scroll-behavior: smooth;
    padding: 0px;
    margin-top: 8px;
    cursor: pointer;
  }
  .home-services-list {
    padding-left: 22px;
  }

  .buttonssimilar {
    font-size: 10px !important;
  }
  .recommended-house {
    padding: 22px 20px !important;
    margin-left: 0px !important;
  }
  .house-name-link {
    font-size: 22px !important;
  }
  .house-address-link {
    font-size: 12px !important;
  }
  .house-desp-recommended {
    /* margin: 10px 0px; */
    height: 8.3rem !important;
    overflow: scroll;
    scroll-behavior: smooth;
  }
  .house-desp-recommended p {
    font-size: 15px;
    text-align: start;
  }
}
/* @media (min-width: 900px) {
  .stepsimg img {
    border-radius: 15px; */
/* width: 100%; */
/* }
  .stepsimg2 {
    margin: 80px 0px 0px 30px;
  }
  .section-3 {
    margin-top: 100px;
  }
} */

/* small laptop  */
@media (min-width: 1000px) and (max-width: 1026px) {
  .navitems {
    gap: 0.5rem;
}
.navlinks {
  font-size: 15px;
  color: #fff !important;
}
  /* .sign-btn {
    margin-right: 53px !important;
  } */
  .latestbtn {
    padding: 11px 8px !important;
    font-size: 16px !important;
  }
  .banheading h1 {
    font-size: 3.5rem;
    margin: 15px 0px;
  }
  .headingsteps {
    margin-top: 10px;
    font-size: 40px;
  }
  .stepsimg2 {
    margin-top: 13rem;
    padding-left: 5rem;
  }
  .benefit1 {
    margin-top: 15px;
  }
  .section-4img {
    text-align: center;
    margin-top: 35px;
  }
  .overlay-section3 {
    position: absolute;
    left: 20px;
    top: 0px;
    width: 50%;
    height: 100%;
    background-color: rgba(8, 77, 36, 0.9);
    z-index: 1;
    transform: skewX(-14deg);
    -webkit-transform: skewX(-14deg);
  }

  .blog-tittle {
    font-size: 17px !important;
    font-weight: 600;
  }
  .blog p {
    font-size: 11px;
  }
  .blog-section h2 {
    font-size: 21px !important;
    margin-bottom: 10px;
  }
  .blog-section p {
    font-size: 13px;
  }

  .popular-sec-content p {
    font-size: 10px;
  }

  .offset-2 {
    margin-left: 6rem;
  }
  .contactus-heading h1 {
    font-size: 45px;
    margin-bottom: 30px;
  }
  .medicaid-sec3 {
    margin-top: 20px;
  }

  .footerul {
    font-size: 19px;
  }
  /* seniorcare home list */
  .home-name h3 {
    /* margin-top: 20px; */
    font-size: 21px !important;
  }
  .heartbtn {
    right: 0.1rem !important;
    top: 0.5rem !important;
    font-size: 22px !important;
  }
  .hearticon {
    font-size: 23px !important;
  }
  /* list home landing page  */
  .list-home-sec p {
    font-size: 15px !important;
    margin-bottom: 0px;
  }

  .buttonssimilar {
    font-size: 10px;
  }
  .recommended-house {
    padding: 22px 20px !important;
    margin-left: 0px !important;
  }
  .frame {
    margin-right: 20px !important;
  }
  .house-name-link {
    font-size: 22px !important;
    font-weight: 600;
  }
  .house-address-link {
    font-size: 15px !important;
  }
  .house-desp-recommended {
    height: 8rem !important;
    overflow: scroll;
    scroll-behavior: smooth;
    padding: 0 10px;
    text-align: start;
    margin-bottom: 15px;
    font-size: 17px !important;
  }

  .reviews {
    display: flex;
    gap: 15px;
    margin-top: 12px;
    justify-content: center;
  }
  .pricing-map {
    margin-top: 4rem !important;
  }
  .list-home-img {
    width: 100%;
    display: block;
    /* margin: 10px auto 18px; */
    margin-bottom: 30px;
    margin-top: 20px !important;
  }
}

/* tablet  */
@media (min-width: 760px) and (max-width: 769px) {
  .navitems {
    gap: 3rem;
    display: block;
    text-align: center;
    line-height: 3;
  }
  .banheading h1 {
    font-size: 4rem;
    margin: 15px 0px;
  }
  .headingsteps {
    margin-top: 10px;
    font-size: 35px;
  }
  .stepsimg {
    margin-left: 100px;
  }
  .stepsimg img {
    width: 70%;
    border-radius: 15px;
  }

  .stepsimg2 {
    margin-top: 7px;
    width: 70%;
  }
  .benefit1 span {
    font-weight: 300;
    font-size: 24px;
  }
  .section-3 {
    margin-top: 5rem;
    padding-bottom: 50px;
  }
  .steps-heading {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .overlay-section3 {
    position: absolute;
    left: -220px;
    top: 0px;
    width: 50%;
    height: 100%;
    background-color: rgba(8, 77, 36, 0.9);
    z-index: 1;
    transform: skewX(-14deg);
    -webkit-transform: skewX(-14deg);
  }
  .sectio-3-background {
    margin-top: 60px;
  }
  .offset-2 {
    margin-left: 0px;
  }
  .provider-btn {
    font-size: 13px;
  }
  .section-4img {
    text-align: center;
  }
  .contactus-heading h1 {
    font-size: 38px;
    margin-bottom: 30px;
  }
  .medicaid-img {
    text-align: center;
  }
  .family-content {
    padding: 25px;
  }
  .landing-blog-email {
    margin-bottom: 20px;
  }
  .latest-recent {
    margin-left: 30px;
  }
  .medicaid-sec3 {
    margin-top: 17px;
  }
  .medicaid-content {
    font-size: 13px;
  }
  .medicaid-content h1 {
    font-size: 25px;
  }
  .medicaid-content h6 {
    font-size: 12px;
  }
  .howtoapply-medicaid {
    margin-top: 10px;
    font-size: 13px;
  }

  .apply-person-mail {
    font-size: 13px;
  }
  .apply-online {
    font-size: 13px;
  }
  .medicaid-sec3-text {
    font-size: 12px;
  }
  .medicaid-sec3-text h3 {
    font-size: 18px;
  }

  .bloglatestsec {
    margin: auto !important;
  }
  .quebox1 {
    width: 36.5rem !important;
  }
  .family-section2-img img {
    margin-bottom: 25px;
    margin-left: 50px;
    width: 80% !important;
  }
  .offset-2 {
    margin-left: 10px !important;
  }
  /* .stepsimg img {
    width: 100% !important;
  } */
  /* blog  */
  .blog-tittle {
    font-size: 17px !important;
    font-weight: 600;
  }
  .blog p {
    font-size: 11px;
  }
  .popular-sec-content p {
    font-size: 16px;
  }
  .popular-sec-img img {
    width: 85% !important;
  }
  .blog-section h2 {
    font-size: 21px !important;
    margin-bottom: 10px;
  }
  .blog-section p {
    font-size: 13px;
  }

  /* provider  */
  .provider-content {
    font-size: 15px;
  }

  /* contact  */
  .contact-section-info p {
    font-size: 15px !important;
  }
  .contact-section h2 {
    font-size: 25px;
    padding-bottom: 5px !important;
  }
  .img-fluid {
    margin-top: 10px;
  }
  .contact-girl-img {
    height: -webkit-fill-available;
    width: 18rem;
    object-fit: contain;
  }
  .committing-help h4 {
    font-size: 19px !important;
  }
  .committing-help p {
    font-size: 12px !important;
  }
  /* footer  */
  .footerul {
    font-size: 20px;
  }
  /* seniorcare home list */
  .topheading {
    margin-top: 1.8rem !important;
    margin-bottom: 1.5rem !important;
    font-size: 1.8rem !important;
    font-weight: 700;
  }
  .home-name h3 {
    margin-top: 20px;
    font-size: 21px !important;
  }
  .servies-provided {
    margin-top: 0px !important;
  }
  .heartbtn {
    right: 0.1rem !important;
    top: 2rem !important;
    font-size: 22px !important;
  }
  .hearticon {
    font-size: 23px !important;
  }

  /* list home landing page  */
  .pricing-map {
    margin: 0 8rem;
  }

  .list-home-sec p {
    font-size: 15px !important;
    margin-bottom: 0px;
  }

  .buttonssimilar {
    font-size: 10px;
  }
  .recommended-house {
    padding: 22px 20px !important;
    margin-left: 0px !important;
  }

  .frame {
    margin-right: 20px !important;
  }
  /* .house-name-link {
    font-size: 17px !important;
  } */
  .house-address-link {
    font-size: 14px !important;
  }

  .house-desp-recommended {
    /* margin: 10px 0px; */
    height: 9rem;
    overflow: scroll;
    scroll-behavior: smooth;
    padding: 0 10px;
    text-align: start;
    margin-bottom: 15px;
    font-size: 14px;
  }
}

/* macbook  */
@media (min-width: 1350px) and (max-width: 1367px) {
  .stepsimg2 {
    margin-top: 5rem;
    width: 70%;
  }
  .contactus-heading h1 {
    color: #ffffff;
    margin: 0px;
    padding: 0px;
    margin-bottom: 25px;
    font-size: 45px;
    font-weight: 600;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navitems {
    gap: 1.5rem;
  }
  .medicaid-sec3 {
    margin-top: 20px;
  }
  .latestbtn {
    padding: 20px 10px !important;
    background: none;
    border: none;
    color: #495057;
    font-weight: 600;
    font-size: 18px !important;
  }
  .benefit1 span {
    margin-top: 15px;
    font-size: 20px;
  }
  /* seniorcare home list */
  .home-name h3 {
    font-weight: 600;
    font-size: 22px !important;
  }
  .house-desp-recommended {
    /* margin: 10px 0px; */
    height: 8rem !important;
    overflow: scroll;
    scroll-behavior: smooth;
    padding: 0 10px;
    text-align: start;
    margin-bottom: 15px;
    font-size: 17px !important;
  }
  .pricing-map {
    margin-top: 3rem !important;
  }
}
/* ipad air  */
@media (min-width: 800px) and (max-width: 822px) {
  /* home */
  .banheading h1 {
    font-size: 3.5rem;
    margin: 15px 0px;
  }
  .steps-heading {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
  .benefit1 span {
    font-weight: 300;
    font-size: 25px;
  }
  .sectio-3-background {
    margin-top: 15px;
  }
  .section-3 {
    margin-top: 12rem;
    padding-bottom: 30px;
  }
  .overlay-section3 {
    position: absolute;
    left: -230px;
    top: 0px;
    width: 50%;
    height: 100%;
    background-color: rgba(8, 77, 36, 0.9);
    z-index: 1;
    transform: skewX(-14deg);
    -webkit-transform: skewX(-14deg);
  }
  .contactus-heading h1 {
    font-size: 35px;
  }
  /* families */
  .family-content {
    margin-top: 10px;
  }
  .offset-2 {
    margin-left: 0rem;
  }
  .family-section2-img img {
    margin-bottom: 25px;
    margin-left: 50px;
    width: 80% !important;
  }
  /* provider  */
  .provider-img1,
  .provider-img2 {
    text-align: center;
  }
  .provider-img1 img {
    width: 80% !important;
  }
  .provider-img2 img {
    width: 50% !important;
    margin-bottom: 20px;
  }
  .ordertwo {
    order: 2;
  }
  .displaynone {
    display: none;
  }
  /* mediaid  */
  .medicaid-sec3 {
    margin-top: 15px;
  }
  .provider-btn {
    font-size: 13px;
  }
  /* blog  */
  .landing-blog-email {
    margin-bottom: 30px;
  }
  .popular-sec-img img {
    width: 85% !important;
  }
  .landing-blog-post {
    margin-left: 10px !important;
  }
  /* contact  */
  .contact-girl-img {
    height: -webkit-fill-available;
    width: 18rem;
    object-fit: contain;
  }
  .committing-help h4 {
    font-size: 19px !important;
  }
  .committing-help p {
    font-size: 12px !important;
  }
  /* footer  */
  .footerul {
    font-size: 20px;
  }

  .buttonssimilar {
    font-size: 10px;
  }
  .list-home-sec p {
    font-size: 15px !important;
    margin-bottom: 0px;
  }
  .house-img img {
    width: 100%;
    height: 12rem;
    object-fit: fill;
    background-size: cover;
  }
  .heartbtn {
    right: 0.1rem !important;
    top: 0.5rem !important;
    font-size: 22px !important;
  }
  .hearticon {
    font-size: 23px !important;
  }
  .pricing-map {
    margin: 0 8rem;
  }
  .frame {
    margin-right: 21px !important;
  }
}
.house-name {
  height: 4rem;
}