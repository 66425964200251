html, body {
    font-family: arial;
  }
  
  #modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
    padding-top: 30px;
    opacity: 0;
    transition: 0.5s ease all;
    z-index: 99999;
  }
  #modal-wrapper.active {
    opacity: 1;
  }
  #modal-wrapper.active #modal-confirmation {
    margin-top: 0;
    opacity: 1;
  }
  
  #modal-confirmation {
    display: inline-block;
    margin-top: -30px;
    opacity: 0;
    max-width: 400px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background: #fff;
    text-align: left;
    transition: 0.5s ease all;
  }
  
  #modal-header {
    position: relative;
    background: #ff6b6b;
    padding: 15px;
    color: #fff;
    text-align: left;
  }
  #modal-header h3 {
    font-weight: 300;
    margin: 0;
  }
  
  #modal-close {
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 1.5em;
    cursor: pointer;
    color: #bd3d3d;
  }
  #modal-close:hover {
    color: #fff;
  }
  
  #modal-content {
    padding: 30px 15px;
  }
  #modal-content p {
    margin: 0;
    color: #61686e;
  }
  
  #modal-buttons {
    padding: 0 15px 15px 15px;
    text-align: right;
  }
  
  #modal-button-no {
    border: 2px solid #98a1a9;
    padding: 10px 30px;
    background: #fff;
    color: #98a1a9;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.5s ease all;
  }
  #modal-button-no:hover {
    background: #98a1a9;
    color: #fff;
  }
  
  #modal-button-yes {
    border: 2px solid #ff6b6b;
    padding: 10px 50px;
    background: #ff6b6b;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.5s ease all;
    margin-left: 10px;
  }
  #modal-button-yes:hover {
    border: 2px solid #ff4242;
    background: #ff4242;
  }
  /* .modal-content{
    width:50%!important;
    justify-content: center!important;
  } */