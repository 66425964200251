.wrapper {
  /* max-width: 1200px; */
  /* margin: 4em auto; */
  overflow: hidden;
}
.buttonssimilar {
  width: 100%;
  margin: 0 0 2em 0;
  text-align: center;
  font-size: 12px;
}
.buttonssimilar a {
  display: inline-block;
  cursor: pointer;
  font-size: 300%;
}
.buttonssimilar a:first-child {
  margin: 0 10px 0 0;
}

.frame {
  /* padding: 120px 0; */
  text-align: center;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
}
.frame i {
  font-size: 1rem;
  font-weight: bold;
  color: #0c0b0b;
}
.house-desp-recommended {
  height: 9rem;
  overflow: scroll;
  scroll-behavior: smooth;
  padding: 0 10px;
  text-align: start;
}
.home-service-activities {
  height: 25rem;
  overflow: scroll;
  scroll-behavior: smooth;
  padding: 0px;
  margin-top: 8px;
  cursor: pointer;
}
.home-service-activities::-webkit-scrollbar {
  display: none;
}
.house-desp-recommended::-webkit-scrollbar {
  display: none;
}

/* @media (min-width: 400px) {
  .frame {
      min-width: 50%;
      max-width: 50%;
    }
} */
@media (min-width: 768px) {
  .frame {
    min-width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 1040px) {
  .frame {
    min-width: 33.6%;
    max-width: 25%;
  }
}

.similarheart {
  font-size: 18px;
  position: absolute;
  /* right: 1rem; */
  top: 134rem;

  color: #fff;
  /* background: transparent; */
  background-color: #75757582;
  border: none;
  border-radius: 50%;
  padding: 8px;
}
