.listed-homes {
  background-color: #fff;
  padding: 15px;
  border-radius: 4px;
}

.listed-homes img {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
  height: 9rem;
}
.listedhome{
  margin-top: 10px;
  text-align: center;
}
.listedhomehr{
  border: 3px solid #10481A;
  border-radius: 5px;
  width: 100px;
 margin: auto;
}
.btn-list-home {
  background-color: #61ce70;
  color: #fff;
  text-decoration: none !important;
  padding: 7px 13px;
  border-radius: 4px;
}
.editicon{
  background:none;
  color: #000;
  border: none;
}