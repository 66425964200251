.list-home-sec {
  /* background-color: #fff; */
  padding: 15px;
  margin-bottom: 15px;
}
.list-home-sec p {
  font-size: 19px;
  margin-bottom: 0px;
}

.list-home-img {
  width: 100%;
  display: block;
  /* margin: 10px auto 18px; */
  margin-bottom: 30px;
  margin-top: 30px;
}
.landingheading {
  font-size: 29px;
  margin-top: 1rem;
  font-weight: 600;
}
.home-services-list {
  list-style-type: none;
}

.home-services-list i {
  margin-right: 14px;
  color: #61ce70;
}
.morebtn {
  color: #000;
}
.listedHomeLanding-form h3 {
  font-weight: 600;
  font-size: 25px;
}
.listedHomeLanding-form {
  margin-bottom: 3rem;
  background-color: #eef7f9;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19);
  padding: 19px 18px;
}
.listedHomeLanding-form input {
  width: 100%;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #938e8e;
  padding: 10px;
  /* margin-top: 2rem; */
}
.listedformbtn {
  margin-top: 2rem;
  text-align: center;
  white-space: nowrap;
  width: 12rem;
  padding: 10px 22px;
  border-radius: 25px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  background-color: #61ce70;
  border: none;
  color: #fff;
}
.listedformbtndiv {
  display: flex;
  justify-content: center;
}

/* recommened house */
.recommeded-house-img img {
  width: 100%;
  height: 13rem;
  background-size: contain;
}
.recommened-heading {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}
.house-name-link {
  text-decoration: none;
  /* color: #61CE70; */
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
.house-name-link:hover {
  text-decoration: underline;
}
.house-address-link {
  text-decoration: none;
  color: #000;
  font-size: 15px;
}

.house-address-link:hover {
  text-decoration: underline;
}
.recommended-house {
  padding: 25px 25px;
  /* border: 1px solid #000; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 20px;
  /* margin-left: 20px;
  margin-top: 10px; */
}
.recommended-more-btn {
  text-align: right;
  margin-top: 10px;
}
.recommended-more-btnlink {
  color: #61ce70;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
}
.recommended-more-btnlink:hover {
  text-decoration: underline;
}
@media (min-width: 300px) and (max-width: 500px) {
  .mobile-view-none {
    display: none;
  }
}

.previosicon {
  background-image: url(https://res.cloudinary.com/dbtsrjssc/image/upload/v1724936940/left-arrow-angle-big-gross-symbol_yhdmmn.png);
  /* background-color: #61CE70; */
  border-radius: 50%;
}
.nexticon {
  background-image: url(https://res.cloudinary.com/dbtsrjssc/image/upload/v1724936940/right-arrow_iy8rtc.png);
  /* background-color: #61CE70; */
  border-radius: 50%;
}
.wrapper {
  /* max-width: 1200px; */
  /* margin: 4em auto; */
  overflow: hidden;
}
/* .buttons {
  width: 100%;
  margin: 0 0 1em 0;
  text-align: right;
} */
.buttons a {
  display: inline-block;
  cursor: pointer;
  font-size: 300%;
}
.buttons a:first-child {
  margin: 0 10px 0 0;
}
/* .container {
  min-width: 100%;
  display: flex;
  flex-direction: row;
} */
.frame {
  margin-right: 6px;
}
.frame i {
  font-size: 500%;
  font-weight: bold;
  color: #fff;
}
@media (min-width: 400px) {
  .frame {
    min-width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .frame {
    min-width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (min-width: 1040px) {
  .frame {
    min-width: 25%;
    max-width: 25%;
  }
}

.recommended-house-img img {
  width: 100%;
  height: 13rem;
  position: relative;
  border-radius: 10px;
}
.previosicon-carousel {
  left: 77rem !important;
  top: -33rem;
}
.nexticon-carousel {
  top: -33rem;
}
.nexticon-carousel,
.previosicon-carousel {
  opacity: 1;
}
.addreviewslink {
  color: #2a342c;
  text-decoration: none;
  display: flex;
  justify-content: right;
  font-weight: 600;
}
.addreviewslink:hover {
  text-decoration: underline;
}
.landinghearticon {
  font-size: 28px;
}
.hearticons {
  font-size: 30px;
  position: absolute;
  /* right: 1rem; */
  top: 6rem;

  color: #fff;
  /* background: transparent; */
  background-color: #75757582;
  border: none;
  border-radius: 50%;
  padding: 8px;
}

@media (min-width: 300px) and (max-width: 500px) {
  .previosicon-carousel {
    left: 13rem !important;
    top: -111rem;
  }
  .nexticon-carousel {
    top: -111rem;
  }
  /* .hearticons{a
  top: 8rem;
} */
  .recommened-heading {
    font-size: 24px;
    padding: 0px 13px;
  }
  .wrapper {
    margin: 0 !important;
  }
  .nameandrating {
    display: block !important;
  }
}
@media (min-width: 760px) and (max-width: 769px) {
  .navitems {
    gap: 3rem;
    display: block;
    text-align: center;
    line-height: 3;
  }
  .btnsall {
    margin: 10px;
    width: 93%;
}
}
