.user-dashboard-contact {
  background-color: #fff;
  padding: 0 0 0 20px;
}

.navs-items {
  color: #000;
  text-decoration: none;
  margin-right: 6.2rem;
  width: 100%;
}

.lists-homes-heading {
  margin-left: 15px;
  margin-top: 10px;
  font-size: 25px;
}

.text-danger {
  color: red;
}

.list-homes-form {
  width: 90%;
  padding: 6px;
  border: 1px solid #bfb9b9;
  border-radius: 3px;
}

.deposit-btn {
  padding: 6px;
  border: 1px solid #bfb9b9;
  border-radius: 3px;
  /* margin-left: 6px; */
  width: 150px;
}
.medicaids {
  padding-top: 35px;
  padding-bottom: 15px;
}

.medicaid-icon {
  margin-left: 8px;
}
.medicaid-info label {
  margin-left: 5px;
  display: inline;
}
.options-margin {
  margin-top: 10px;
}

.specialist-btn,
.next-btn {
  background-color: #61ce70;
  text-decoration: none;
  color: #fff;
  padding: 12px;
}

.next-btn {
  padding: 11px 49px;
}
/* @media (min-width: 300px) and (max-width: 500px) {
  .lists-homes-heading {
    margin-top: 50px;
    font-size: 25px;
  }
} */
