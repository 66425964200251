.afh-services-name,
.services-list {
  background-color: #fff;
  padding: 15px;
}
/* .afh-service {
  padding: 0 0 0 15px;
} */

.services-names {
  display: flex;
  padding: 11px;
  justify-content: space-around;
}

.names,
.company-names,
.services,
.phone-no {
  padding-left: 6rem;
  list-style: none;
}

.company-names,
.services {
  padding-left: 65px;
}

.phone-no {
  padding-left: 85px;
}

.names li,
.company-names li,
.services li,
.phone-no li {
  margin-bottom: 18px;
}
.header-width {
  min-width: 9rem;
}




