@import '../../sass/variable.scss';

.medicaidlink{
 text-decoration: none;
}
.medicaidlink:hover{
color: #000;
}
.familybanner p{
    color:$para-color;
    padding-bottom: 3rem;
}
.medicaid-content p{
 color: $para-color;
}
.medicaid-content h6{
    color: #000;
    font-weight: 600;
}
.medicaid-content ol li{
    color:$para-color
}
.howtoapply p{
    color: $para-color;
}
.howtoapply li{
    color: $para-color;
}
.medicaid-sec3 img{
    width: 100%;
    border: 10px solid #084D24!important;
}