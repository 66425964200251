/* SignIn.css */

.sign-in-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  background: #f6f5f7;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 52%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  transition: 1s 0.7s ease-in-out;
  z-index: 5;
}

.sign-in-form,
.sign-up-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
  margin-top: 2rem;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 56x;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field input,
select,
option {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #aaa;
}
.showpass{
  margin-right: 14rem;
  display: flex;
  gap: 10px;
}
.showpass span{
  color: #444444bd;
  font-size: 14px;
}
.sign-in-container {
  background-color: #fff;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.social-text {
  padding: 0rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #61ce70;
  border-color: #61ce70;
}

.btns {
  width: 150px;
  background-color: #61ce70;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btns:hover {
  background-color: #15502c;
}

.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.sign-in-container:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #084d24 0%, #415a48 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.btns.transparent {
  /* background: none; */
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
}

.sign-up-mode .left-panel .image,
.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.sign-up-mode .signin-signup {
  left: 25%;
}

.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.sign-up-mode .right-panel .image,
.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.sign-up-mode .left-panel {
  pointer-events: none;
}

.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .sign-in-container {
    min-height: 800px;
    height: 100vh;
  }

  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .panel .content,
  .panel .image {
    padding: 0.5rem;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .left-panel {
    grid-row: 1 / 2;
    pointer-events: none;
  }

  .right-panel {
    grid-row: 3 / 4;
    pointer-events: all;
  }

  .sign-up-mode .left-panel .content,
  .sign-up-mode .left-panel .image,
  .sign-up-mode .right-panel .content,
  .sign-up-mode .right-panel .image {
    transform: translateX(0);
  }

  .sign-up-mode:before {
    transform: translate(-50%, -50%);
    right: 50%;
  }
}

@media (max-width: 570px) {
  /* form {
    padding: 0 1.5rem;
  } */

  .panel .content {
    padding: 0.5rem 1rem;
  }

  .btns.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.8rem;
  }

  .image {
    display: none;
  }
}

.right-panel {
  transform: translateX(100%);
}

.hide {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f0f0f0 inset !important; /* Change lightblue to your desired color */
  -webkit-text-fill-color: #aaa !important; /* Change black to your desired text color */
}

.signinbtn {
  text-decoration: none;
  color: #fff;
}

@media (min-width: 300px) and (max-width: 500px) {
  .sign-in-form,
  .sign-up-form {
    padding: 0 2rem;
  }
  .content {
    color: #000 !important;
  }
  .btnresp {
    border: 1px solid #084d24 !important;
    color: #000;
  }
  .sign-in-container:before {
    display: none;
  }
  .signin-signup {
    /* top: 90%; */
  }

  .left-panel {
    display: none;
  }
  .signin-margin {
    position: relative;
    top: -89%;
  }
  .showpass {
    margin-right: 8rem;
  }
}
.social-text button {
  color: blue;
  border: none;
  background: none;
  cursor: pointer;
}
@media(min-width:760px) and (max-width:769px){
  .sign-in-container:before{
    display: none;
  }
  .left-panel{
    display: none;
  }
  .signin-margin{
    position: relative;
    top: -89%;
  }
  .showpass {
    margin-right: 15rem;
  }
  .title{
    color: #61ce70;
    margin-top: 5rem!important;
  }
  .sign-in-container {
    min-height: 839px;
  }
}