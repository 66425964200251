:root {
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.5;
    font-weight: 400;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --primary: hsl(270, 100%, 50%);
    --white: #fff;
    --black: #000;
    --darker: color-mix(in oklab, var(--primary), var(--black, #000) 15%);
    --lighter: color-mix(in oklab, var(--primary), var(--white, #fff) 20%);
  }
  
  :is(*, *::before, *::after) {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .main-wrapper {
    height: 100dvh;
    display: grid;
    place-items: center;
  }
  
  .steps-wrapper {
    max-width: 400px;
    width: 100%;
  }
  .steps-wrapper .steps {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .steps-wrapper .steps .step {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #dedede;
    border-radius: 50%;
    color: #878787;
    background: white;
    font-size: 24px;
    font-weight: 600;
    transition: 200ms ease;
    transition-delay: 0ms;
  }
  .steps-wrapper .steps .step.active {
    color: var(--primary);
    border-color: var(--primary);
    transition-delay: 100ms;
  }
  .steps-wrapper .steps .progress-bar {
    position: absolute;
    width: 100%;
    height: 4px;
    background: #dedede;
    z-index: -1;
  }
  .steps-wrapper .steps .progress-bar .progress {
    position: absolute;
    height: 100%;
    background: var(--primary);
    transition: 300ms ease;
  }
  .steps-wrapper .buttons {
    margin-block-start: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .steps-wrapper .buttons .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 6px;
    border: none;
    background: var(--primary);
    color: var(--white);
    cursor: pointer;
    transition: 200ms linear;
  }
  .steps-wrapper .buttons .btn:active {
    scale: 0.9;
  }
  .steps-wrapper .buttons .btn:hover:not(.steps-wrapper .buttons .btn:disabled) {
    background: var(--darker);
  }
  .steps-wrapper .buttons .btn:disabled {
    cursor: not-allowed;
    background: var(--lighter);
    pointer-events: none;
  }