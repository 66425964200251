/* General */

  .pagination ul {
    margin: 2em auto;
    padding-left: 0;
    list-style-type: none;
  }
  
  .page-number {
    display: inline;
  }
  
  .page-number a {
    text-decoration: none;
    color: black;
  }
  .page-number a:hover{
    text-decoration: none;
    color: #61ce70;
  } 
  /* Pagination 1 */
  .pagination-1 .page-number {
    margin: 0.25em;
  }
  
  .pagination-1 .page-number a {
    padding: 0.125em;
  }
  
  .pagination-1 .active a {
    font-weight: bold;
    border-bottom: 3px solid black;
  }
  
  .pagination-1 .prev,
  .pagination-1 .next {
    text-transform: uppercase;
  }
  
  /* Pagination 2 */
  .pagination-2 .page-number {
    padding: 8px 16px;
    background-color: #f3f4f2;
  }
  
  .pagination-2 .page-number:hover {
    background-color: #d9dcd6;
  }
  
  .pagination-2 .active {
    border-radius: 4px;
    background-color: #61ce70;
  }
  
  .pagination-2 .active:hover {
    background-color: #16425b;
  }
  
  .pagination-2 .active a {
    color: #f3f4f2;
  }
  
  /* Pagination 3 */
  .pagination-3 .page-number {
    margin: 0.25em;
  }
  
  .pagination-3 .page-number a {
    padding: 0.125em;
  }
  
  .pagination-3 .active {
    padding: 4px 8px;
    border-radius: 50%;
    background-color: #61ce70;
    color: white;
  }
  
  .pagination-3 .active a {
    color: white;
  }
  