.user-contactus-div {
  width: 45%;
  background-color: #f4f7f3;
  margin-bottom: 20px;
  margin-left: 35px;
}
.user-contactus-data {
  padding: 20px 10px;
}
.user-contactus-data label {
  line-height: 10px;
}
.user-contactus-btn {
  padding: 0px 30px;
  display: flex;
  margin-bottom: 35px;
  flex-direction: column;
  justify-content: space-between;
}
.user-contactus-btn button {
  color: #fff;
  /* background-color: #61CE70; */
  border: none;
  padding: 11px 10px;
  border-radius: 7px;
}
.respond button {
  background-color: #4fc6e1;
  width: 100%;
}
.deletebtn button {
  background-color: #ee324d;
  padding: 11px 10px;
  width: 100%;
}
.resolve button {
  background-color: #97c160;
  width: 100%;
}
.ques {
  display: flex;
  
  width: 100%;
}

.ques span {
  word-break: break-word;    
  white-space: normal;      
 
}

@media (min-width: 300px) and (max-width: 500px) {
  .user-contactus-div {
    width: 90%;
    background-color: #f4f7f3;
    margin-bottom: 20px;
    margin-left: 35px;
  }
  .user-contactus-btn {
    padding: 25px 0px;
    display: flex;
    margin-bottom: 35px;
    flex-direction: column;
    justify-content: space-between;
  }
}
