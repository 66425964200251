.dash-navbar {
  background-color: #10481a;
  padding: 15px 35px 20px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-logo {
  width: 120px;
}

.profile {
  display: flex;
}

.dashboard-bell {
  color: white;
  font-size: 25px;
  margin-right: 25px;
  cursor: pointer;
}

.dashboard-userprofile {
  color: white;
  font-size: 25px;
}
.drop-down-arrow {
  color: white;
  margin-left: 15px;
  font-size: 20px;
}
.drop-down-arrow .dropdown-menu {
  color: white;
  margin-top: 15px;
}

/* .drop-down-arrow .drop-down-arrow-item {
  color: #333;
}

.drop-down-arrow .drop-down-arrow-item:hover {
  background-color: #10481a;
  color: white;
} */

/* **************** */

/* .offcanvas-admin-dropdown-items {
  padding: 10px;

  margin: 10px;
  font-size: 15px;

  color: white;
  display: block;

  text-decoration: none;
  border-radius: 4px;
} */

/* .offcanvas-admin-dropdown-items:hover {
  background-color: #76a17e;
  color: white;
}
.offcanvas-admin-dropdown-items.active {
  background-color: #76a17e;
  color: white;
} */
.offcanvas-admin-menu {
  background-color: #10481a;
}

/* .offcanvas-admin-dropdown-item {
  color: white;
  font-size: 14px;
  padding: 7px 17px !important;
}

.offcanvas-admin-dropdown-item:hover {
  background-color: #76a17e;
  color: white;
} */
.admin-userprofile {
  margin: 7px 0 0px 23px;
}
.cross-close {
  font-size: 25px;
  color: white;

  padding: 3px;
  cursor: pointer;
}
.cross-close:hover {
  color: #97c160;
}
.offcanvas-admin-menu {
  color: white;
  font-size: 23px;
}
.offcanvas-admin-menu:hover {
  color: #97c160;
}
.navbar-dropdown-home:hover {
  background-color: #76a17e;
  color: white;
}

/* .offcanvas-custom-dropdown .dropdown-menu {
  width: 213px;
} */

@media (min-width: 300px) and (max-width: 770px) {
  .dash-navbar {
    padding: 15px 15px 15px 20px;
  }
  .company-logo {
    width: 80px;
  }
  /* .dashboard-bell,
  .dashboard-userprofile {
    font-size: 25px;
  } */

  .admin-user-profile {
    display: none;
  }

  .drop-down-arrow {
    margin-top: 5px;
  }
}
@media (min-width: 300px) and (max-width: 500px) {
  .custom-offcanvas {
    max-width: 85%;
  }
}
@media (min-width: 500px) and (max-width: 770px) {
  .custom-offcanvas {
    max-width: 40%;
  }
}
@media (min-width: 770px) and (max-width: 991px) {
  .admin-user-profile {
    display: none;
  }
}
