/*================= testimonial ==========================*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap");

:root {
  --primary-color: #041c32;
  --secondary-color: #04293a;
  --ghots-color: #f1f1f1;
  --transprent-color: transparent;
  --shadow-sm: 10px -4px 2px #0dcaf0;
  --rounded-50: 50%;
  --rounded-10: 0.625rem;
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* body {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  height: 100%;
  color: var(--ghots-color);
  background-color: var(--primary-color);
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  background: var(--transparent-color);
} */

/* h1 {
  font-size: clamp(2rem, 2.5vw, 3rem);
  margin-bottom: 2.5rem;
  font-weight: 500;
  text-align: center;
} */

/* section {
  display: grid;
  place-items: center;
  height: 100vh;
} */

/* .container {
  padding-inline: 1rem;
  width: 500px;
} */

section .card {
  min-height: 100%;
  /* min-width: 1rem; */
  /* background-color: #EEF7F9; */
  background-color: transparent;
  /* padding: 2rem; */
  border-radius: var(--rounded-10);
  word-wrap: break-word;
  text-align: center;
  margin-inline: auto;
  /* border: none!important; */
}
.nameandrating{
align-items: center;
justify-content: center;
gap: 5px;
}
.ratingreviews{
  color: #FBB65D;
}
section .card img {
  max-width: 100%;
  height: 8rem;
  object-fit: cover;
  width: 8rem;
  border-radius: var(--rounded-50);
  margin-inline: auto;
  margin-bottom: 0.625rem;
}

section .card-body .card-title {
  font-size: clamp(1.2rem, 1.4vw, 2rem);
  font-weight: 400;
  margin-bottom: 0.625rem;
  color:#000;
}

section .card-body p {
  font: size 0.875em;
  margin-bottom: 0.625rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
}

section .card-body p + button {
  margin-right: 0.57rem;
}
.card-footer button{
	outline: none;
	border: none;
	cursor: pointer;
	background: var(--transparent-color);
}
.card-footer,.card-header {
	background-color: transparent!important;
	border-top: none!important;
	border-bottom: none!important;
}