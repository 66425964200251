.user-side-main {
  height: 100vh;
  background-color: #10481a;
  padding: 10px;
  position: absolute;
  z-index: 10;
}

.user-menu {
  color: white;
  font-size: 25px;
  padding-left: 10px;
  cursor: pointer;
}

.user-side-menu-bar {
  display: flex;
  flex-direction: column;
}

.user-sidemenubar-dec {
  text-decoration: none;
  color: white;
  text-align: center;
}
.user-menu-icon {
  font-size: 27px;
  margin-bottom: 5px;
}

.user-sidemenubar-one {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 17px;
  margin-top: 20px;
}

.user-sidemenubar-one:hover {
  background-color: #76a17e;
}

.user-sidemenubar-dec.active .user-sidemenubar-one {
  background-color: #76a17e;
}
/* *::-webkit-scrollbar {
    display: none;
  } */

.user-sidemenubar-dropdrop {
  display: none;
  padding: 0;
}

.user-dropdown.active .user-sidemenubar-dropdrop {
  display: block;
}

.user-dropdown-item {
  color: white;
  display: block;
  padding: 5px 40px;
  text-decoration: none;
  border-radius: 4px;
  font-size: 16px;
}

.user-dropdown-item:hover {
  background-color: #4a7c5c;
  color: white;
}

.user-dropdown-item.active {
  background-color: #76a17e;
  color: white;
}

.user-arrow-icon {
  font-size: 20px;
}
/* .user-outlet {
  height: 99vh;
  overflow: scroll;
  padding: 30px 10px 5px 70px;
  margin-left: 50px;
  position: absolute;
  margin-top: 5.5rem;
} */
/* .user-outlet::-webkit-scrollbar {
  display: none;
} */

@media (min-width: 300px) and (max-width: 500px) {
  .user-menu {
    color: white;
    font-size: 19px;
    padding-left: 10px;
  }

  .user-arrow-icon {
    font-size: 19px;
  }

  .user-sidemenubar-one {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 7px;
    cursor: pointer;
    border-radius: 5px;
  }
  /* .user-outlet {
    height: 99vh;
    overflow: scroll;
    padding: 0px 10px 5px 45px;
    margin-left: 50px;
    margin-top: 4rem;
  } */
}
/* @media (min-width: 500px) and (max-width: 769px) {
  .user-side-main {
    height: 33.5rem;
    background-color: #10481a;
    padding: 10px;
    position: absolute;
    z-index: 10;
  }
}
@media (min-width: 770px) and (max-width: 1024px) {
  .user-side-main {
    height: 46.4rem;
    background-color: #10481a;
    padding: 10px;
    position: absolute;
    z-index: 10;
  }
} */
@media (min-width: 300px) and (max-width: 991px) {
  .user-side-main {
    display: none;
  }
}
