.succ-modal__text{
    gap: 14px;
text-align: center;
    font-size: 21px;
}
.succ-modal__text p{
    margin-top: 10px;
}
.modalfooter{
    border: none;
}
.succ-icon-image,.succ-icon svg{
    width: 4rem;
}
.stripe{
    font-size: 18px;
    text-decoration: none;
}