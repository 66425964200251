.blog-section {
  border: 1px solid #00000020;
  padding: 25px;
  margin: 10px;
}

.blog-section h2 {
  color: black;
  text-align: center;
  font-weight: 600;
  font-size: 28px;
}

.blog-section img {
  width: 100%;
}

.blog-section p {
  color: #68717b;
}

.voice-assistant h3,
.hearing-aid h3,
.medical-apps h3,
.e-commerce-website h3,
.entertainment h3,
.social-media h3 {
  color: #000;
}

.hearing-aid-img {
  width: 100%;
}

.entertainment img {
  width: 100%;
}

.blog-comment {
  margin: 10px;
}

.blog-comment input {
  width: 100%;
  padding: 10px;
  outline: 0;
  border: 1px solid #68717b;
}

#landing-textarea {
  width: 100%;
}

.landing-submit-btn {
  color: white;
  background-color: #61ce70;
  padding: 10px;
  text-decoration: none;
  margin-bottom: 15px;
  padding-right: 55px;
  padding-left: 48px;
}

.landing-recent-blog {
  border: 1px solid #00000020;
  margin-top: 10px;
  padding: 25px;
}

.line {
  border-bottom: 3px solid #61ce70;
}

.landing-blog-email input {
  width: 60%;
  outline: 0;
  border-radius: 3px;
  border: 1px solid #68717b;
  padding: 10px;
}

.subscribe-btn {
  color: white;
  /* width: 7rem; */
  letter-spacing: 1px;
  font-weight: 600;
  padding: 10px;
  text-decoration: none;
  background-color: #61ce70;
}
.latestbtn {
  padding: 20px;
  background: none;
  border: none;
  color: #495057;
  font-weight: 600;
  font-size: 18px;
}
.landing-blog-post {
  margin-left: 22px;
}

@media (min-width: 300px) and (max-width: 500px) {
  .latestbtn {
    padding: 11px 11px;
    font-size: 15px;
  }
  .landing-blog-post {
    margin-left: 0px;
  }
  .landing-blog-post {
    margin-left: 21px;
  }
}
