.side-navbar {
  background-color: #8080800d;
  height: 38.9rem;
}

.side-nav {
  border-radius: 9px;
}

.side-nav-item {
  padding: 10px;
  margin-bottom: 2rem;
  cursor: pointer;
}

.side-nav-item i {
  border-radius: 7px;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
  padding: 7px;
  background-color: white;
  font-size: 16px;
}

.side-nav-link {
  font-size: 20px;
  padding-left: 25px;
  color: black;
  text-decoration: none;
}

.side-nav :active {
  box-shadow: 0 0.3125rem 0.625rem 0 rgb(30 29 29 / 54%);
  border-radius: 9px;
  background-color: white;
}

/* .profile-section {
  padding: 10px;
} */

.input-name {
  border: none;
  outline: none;
  color: black;
  letter-spacing: 1px;
}

.input-name:focus-visible {
  border: none;
  outline: none;
}

.circle-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

#care {
  padding: 8px;
  border: none;
  width: 100%;
  border-radius: 6px;
}

.dropdown_menus {
  /* transform: translate3d(0px, 42px, 0px) !important; */
  overflow-y: auto;
  height: 15rem;
  width: 34rem;
}

.type_care_btn {
  background-color: #fff !important;
  color: black !important;
  text-align: justify;
  width: 100%;
  border-color: white !important;
}

.type_care_btn:focus-visible {
  border-color: white !important;
}

.type_care_btn:hover {
  border-color: white !important;
}

input[type="checkbox" i] {
  cursor: pointer;
}

.text-danger {
  color: red;
}

.profile-submit-btn {
  background-color: #61ce70;
  color: white;
  text-decoration: none;
  padding: 8px 34px;
  border: none;
}

.profile-user-img {
  width: 10%;
}
.input-error {
  border: 1px solid red;
}
.nav-link:hover{
color: #61ce70;
}