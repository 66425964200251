.contact-section h2 {
  font-weight: 600;
  color: #000;
  padding-bottom: 18px;
}

.contact-section-info p {
  font-size: 18px;
  color: #000;
}
.contact-infonumber{
  color: #fff;
  font-size: 19px;
  text-align: center;
  font-weight: 700;
}
.phnno-btn{
 background: none;
 border: none;
 color: #61ce70;
}
.contact-infonumber span{
  color: #61ce70;
}
.contact-form input {
  width: 100%;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #938e8e;
  padding: 10px;
}

.contact-form input:focus-visible {
  border-color: #938e8e;
}

.contact-form input:focus,
.contact-form textarea:focus,
.contact-form select:focus {
  border-color: #938e8e !important;
  box-shadow: none !important;
}

.contact-form input:hover {
  border-color: #938e8e;
}

.contact-form textarea {
  width: 100%;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #938e8e;
  padding: 10px;
}

.contact-form select {
  width: 100%;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #938e8e;
  padding: 10px;
}

.help-you-section {
  padding: 56px;
  /* background-color: #0a4e26; */
}

.form-contactUs {
  padding-top: 20px;
  background-color: #0a4e26;
}

/* .contact-girl-img {
  width: 100%;
  height: 41rem;
} */
.contact-girl-img {
  height: -webkit-fill-available;
}
.error-message {
  color: red;
}

.committing-help h4 {
  font-size: 28px;
  font-weight: 600;
  color: #fff;
}

.committing-help p {
  font-size: 18px;
  color: #fff;
}

.contact-btn {
  color: white;
  background-color: #61ce70;
  padding: 10px;
  text-decoration: none;
  padding-right: 55px;
  padding-left: 48px;
}
.recat {
  width: 100%;
}

.re-captch {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 300px) and (max-width: 500px) {
  .help-you-section {
    padding: 0 !important;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .contact-girl-img {
    display: none;
  }
  .resfeild {
    margin-top: 1.5rem;
  }
  .contact-btn {
    padding-left: 40px;
  }
  .orderone {
    order: 1;
  }
  .ordertwo {
    order: 2;
  }
}
