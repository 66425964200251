.homeDescription {
  background-color: #fff;
}

.websiteUrl input,
.detail-description textarea {
  width: 94%;
  border: 1px solid #aaa;
  color: #aaa;
  padding: 6px;
  border-radius: 3px;
}

.detail-description textarea {
  width: 100%;
  height: 6rem;
  height: auto;
  margin-top: 0.5rem;
}

.pre-btn {
  background-color: #61ce70;
  color: white;
  text-decoration: none;
  padding: 5px 36px;
}

.file-input-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
}

.file-input {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.browse-button {
  border: 2px solid #61ce70;
  color: white;
  background-color: #61ce70;
  padding: 3px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}

.image-preview,
.video-preview {
  width: 156px;
  padding: 6px;
  margin-left: -10rem;
}

.input-group-btn button:hover {
  background-color: darkgreen !important;
  color: white !important;
}

.input-group-btn button:active {
  background-color: darkgreen !important;
  color: white !important;
}