.searchInputWrapper {
    position: relative;
    margin-bottom: 25px;
    width: 14rem;
    /* margin-right: 5rem; */
  }
  
  .searchInput {
    width: 15rem;
    height: 2.5rem;
    padding: 0 1rem; 
    border-radius: 2rem;
    border: none;
    transition: transform 0.1s ease-in-out;
    background-color: #032509;
    color: #fff;
  }
  .delete-btn{
    margin-top: 8px;
  }
  .delete-btn i{
  color: red;
  }
  .searchbardiv{
    gap: 3rem;
}
  ::placeholder {
    color: #a1a1a1;
  }
  
  /* hide the placeholder text on focus */
  :focus::placeholder {
    text-indent: -999px
  }
  
  /* .searchInput:focus {
    outline: none;
    transform: scale(1.1);
    transition: all 0.1s ease-in-out;
  } */
  
  .searchInputIcon {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    color: #a1a1a1;
    transition: all 0.1s ease-in-out;
  }
  
  /* .container:focus-within > 
  .searchInputWrapper > .searchInputIcon {
    right: 0.2rem;
  } */