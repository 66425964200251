.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* background-color: #000; */
    color: #000;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    z-index: 1000;
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);

  }

/* CookieConsent.css */
.no-scroll {
  overflow: hidden;
  height: 100vh; /* Ensures the body doesn't scroll */
}


  .cookie-consent button {
    background-color: #4caf50;
    border: none;
    color: white;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .cookie-consent button:hover {
    background-color: #45a049;
  }
  