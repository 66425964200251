.user-dashboard-faq {
  padding: 5px 0 0 10px;
}

.faq-provider {
  display: flex;
  gap: 2rem;
}

.faq-provider h3 {
  font-size: 20px;
}

.faq-provider-btn {
  background-color: #61ce70;
  color: white;
  text-decoration: none;
  padding: 4px 20px;
}
.faqheading {
  text-align: center;
  /* margin-top: 2rem; */
  font-size: 40px;
  background: -webkit-linear-gradient(#61ce70, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (min-width: 300px) and (max-width: 500px) {
  .faqheading {
    font-size: 25px;
    padding-left: 10px;
    margin-top: 20px;
  }
  .faq-provider {
    display: block;
  }
  .faq-provider h3 {
    font-size: 15px;
  }
}
