.home-listed,
.total-leads,
.total-views {
  background-color: #fff;
  padding: 21px 10px;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.home-listed span,
.total-leads span,
.total-views span {
  background-color: #ed960f;
  color: #fff;
  font-size: 17px;
  padding: 5px;
  border-radius: 10px;
}

.home-listed p,
.total-leads p,
.total-views p {
  text-align: center;
  margin-top: -9px;
  color: #ed960f;
  font-size: 4.8rem;
  margin-bottom: -1rem;
}

.home-listed:hover,
.total-leads:hover,
.total-views:hover {
  border-radius: 10px;
}

.total-leads span {
  background-color: #69bfcf;
}

.total-leads p {
  color: #69bfcf;
}

.total-views span {
  background-color: #85bb4a;
}

.total-views p {
  color: #85bb4a;
}

.list-any-homes {
  background-color: white;
  padding: 25px;
}

.list-any-homes p {
  text-align: center;
  font-size: 20px;
}

.btn-list-home,
.btn-recent-blog {
  background-color: #61ce70;
  color: #fff;
  text-decoration: none !important;
  padding: 7px 35px;
}

.dashboard-blog {
  background-color: #fff;
  padding: 15px;
}

.dashboard-blog h2 {
  font-size: 20px;
}

.recent-blog img {
  width: 100%;
}

.recent-blog h3 {
  margin-top: 10px;
  font-size: 18px;
}

.recent-blog p {
  font-size: 14px;
}

.btn-recent-blog {
  padding: 7px 15px;
}

/* @media (min-width: 760px) and (max-width: 769px) {
  .dashboard-blog {
    background-color: #fff;
    padding: 15px;
    width: 50%;
  }
} */
