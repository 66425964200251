.adminblogform input{
width: 70%;
height: 2.3rem;
}
.adminblogform label{
    margin-top: 20px;
}
.updatedblogs{
    margin-top:5rem;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);
    word-wrap: break-word;
    background-color: #F2F5F7;
    background-clip: border-box;
    border: 0 solid #f7f7f7;
    border-radius: .25rem;
}
.updatedblog-image img{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
}
.updatedblogs label{
    font-weight: 600;
}
.updated-info{
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
 margin: auto;
}
.edit{
    width: 8rem;
    height: 2.5rem;
    font-size: 15px;
    background-color: #394d67;
    border: none;
    color: #fff;
}
.publish{
    width: 8rem;
    height: 2.5rem;
    font-size: 15px;
    background-color: #97c160;
    border: none;
    color: #fff;
}
.delete{
    width: 8rem;
    height: 2.5rem;
    font-size: 15px;
    background-color: #f1556c;
    border: none;
    color: #fff;
}
.card-box {
    background-color: #fff;
    padding: 1.5rem;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);
    margin-bottom: 24px;
    border-radius: .25rem;
}
.avatar-sm {
    height: 2.25rem;
    width: 2.25rem;
}
.count{
    text-align: right;
}
.progress-sm {
    height: 5px;
}
.seachbar{
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: end;
}
.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}
.delete-btn button{
    background: none;
    border: none;
}
.delete-btn i{
   font-size: 18px;
}
