.table_responsive {
  background-color: #efefef33;
  padding: 3px;
  overflow: auto;
  width: 100%;
  margin: auto;
}

.tablemain {
  width: 100%;
  font-size: 13px;
  color: #444;
  white-space: nowrap;
  border-collapse: collapse;
}
.table-heading{
  margin-left: 4rem;
}
.admin-side-menubar-one:hover,
.admin-side-menubar-one.active {
  width: 200px;
}
.tablemain>.tableheader {
  background-color: #032509;
  color: #fff;
}

.tablemain>.tableheader th {
  padding: 8px 15px;
  font-size: 16px;
}

.tablemain th,
.tablemain td {
  border: 1px solid #00000017;
  padding: 10px 15px;
  font-size: 18px;
}

.tablemain>tbody>tr>td>img {
  display: inline-block;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 2px 6px #0003;
  object-fit: contain;
}

.tablemain>tbody>tr>td>div>img {
  display: inline-block;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 2px 6px #0003;
}

.action_btn {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.action_btn>a {
  text-decoration: none;
  color: #444;
  background: #fff;
  border: 1px solid;
  display: inline-block;
  padding: 7px 20px;
  font-weight: bold;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
}

.action_btn>a:nth-child(1) {
  border-color: #26a69a;
}

.action_btn>a:nth-child(2) {
  border-color: orange;
}

.action_btn>a:hover {
  box-shadow: 0 3px 8px #0003;
}

.tablemain>tbody>tr {
  background-color: #fff;
  transition: 0.3s ease-in-out;
}

.tablemain>tbody>tr:nth-child(even) {
  background-color: rgb(238, 238, 238);
}

.cancelled {
  color: red;
  font-size: 17px;
  text-transform: capitalize;
}

.switch {
  display: inline-flex;
  height: 33px;
  position: relative;
  width: 3.8rem;
  align-items: center;
  justify-content: flex-start;
}

.switch input {
  display: none;
}

.switch>span {
  display: inline-flex;
  justify-content: flex-start;
  position: absolute;
  left: -2rem;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: '';
  height: 25px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked+.slider {
  background-color: #66bb6a;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* @media(min-width:300px) and (max-width:500px){
  .table_responsive{
    width: 100%!important;
  }
  .searchInput{
    width: 13rem!important;
  }
  .searchbardiv {
    gap: 0!important;
  }
  .searchInputIcon {
    right: 1.8rem!important;
  }
}
@media(min-width:1020px) and (max-width:1025px){
  .table_responsive {
    width: 83%!important;
    margin-left: 8rem!important;
    /* margin: auto; */
/* }
.pagination{
  margin-left: 5rem!important;
}
.table-heading {
  margin-left: 8rem;
}
} */ 