.provider {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 10px;
}
.provider input {
  width: 100%;
  height: 40px;
}
.provider input::placeholder {
  padding-left: 10px;
  color: gray;
}
