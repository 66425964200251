.medicaidlink {
  text-decoration: none;
}

.medicaidlink:hover {
  color: #000;
}

.familybanner p {
  color: gray;
  padding-bottom: 3rem;
}

.medicaid-content p {
  color: gray;
}

.medicaid-content h6 {
  color: #000;
  font-weight: 600;
}

.medicaid-content ol li {
  color: gray;
}

.howtoapply p {
  color: gray;
}

.howtoapply li {
  color: gray;
}

.medicaid-sec3 img {
  width: 100%;
  border: 10px solid #084D24 !important;
}/*# sourceMappingURL=Medicaidpage.css.map */