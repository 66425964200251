/* Base styles for desktop/laptop */
/* .cart {
  padding: 20px 80px;
}

.cart-items-title {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  color: grey;
  font-size: 17px;
}

.cart-item-heading {
  margin-bottom: 50px;
  font-weight: 600;
}

.info {
  font-size: 16px;
  font-weight: 600;
}

.read {
  color: rgb(75, 124, 239);
}

.details {
  margin-top: 5px;
  width: 500px;
}

.btn {
  margin-top: 4px;
  font-weight: 600;
  border-radius: 20px;
  border: 3px solid green;
}

.btn:hover {
  border-radius: 20px;
  border: 3px solid rgb(195, 232, 195);
}

.review-score {
  color: green;
}

.icons {
  padding-left: 10px;
  font-size: 27px;
  margin-top: 9px;
}

.btn-remove {
  padding: 10px;
  margin-top: 50px;
  border: none;
  background-color: white;
  font-size: 30px;
}

.cart-items-item {
  margin: 10px 0px;
  color: black;
}

.cart-items-item img {
  width: 200px;
}

.cart hr {
  height: 1px;
  background-color: #4a4646;
  border: none;
}

.cart-items-item {
  cursor: pointer;
} */
/* Mobile styles */
/* @media (min-width: 10px) and (max-width: 576px) {
  .cart {
    padding: 30px;
  }

  .cart-items-title {
    display: none;
  }

  .cart-item-heading {
    margin-bottom: 30px;
    text-align: center;
  }

  .cart-items-item {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    position: relative;
  }

  .cart-items-item img {
    width: 100%;
  }

  .info {
    font-size: 14px;
    margin-top: 20px;
  }

  .details {
    width: 100%;
    font-size: 14px;
  }

  .btn {
    margin-top: 10px;
    font-size: 14px;
    padding: 10px;
    border: 2px solid green;
    width: 80%;
  }

  .icons {
    font-size: 22px;
    margin-top: 10px;
  }

  .btn-remove {
    position: absolute;
    top: -50px;
    right: 10px;
    font-size: 24px;
    padding: 5px;
    border: none;
    background-color: transparent;
    color: white;
  }

  .review-score {
    margin-top: 5px;
  }
} */

/* Tablet styles */
/* @media (min-width: 577px) and (max-width: 768px) {
  .cart {
    padding: 15px;
  }

  .cart-items-title {
    grid-template-columns: 1.5fr 2.2fr 1fr;
    font-size: 15px;
  }

  .cart-item-heading {
    margin-bottom: 40px;
    text-align: center;
  }

  .cart-items-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 15px 0;
  }

  .cart-items-item img {
    width: 200px;
    margin-right: 15px;
  }

  .info {
    width: 50%;
    font-size: 15px;
    padding-left: 10px;
  }

  .details {
    width: 90%;
    font-size: 13px;
  }

  .btn {
    margin-top: 12px;
    font-size: 15px;
    padding: 10px;
    border: 2px solid green;
  }

  .icons {
    font-size: 20px;
  }

  .btn-remove {
    font-size: 26px;
  }

  .review-score {
    display: inline-block;
  }
} */

/* Laptop styles */
/* @media (min-width: 769px) and (max-width: 1025px) {
  .cart {
    padding: 20px 40px;
  }

  .cart-items-title {
    grid-template-columns: 1.7fr 2.7fr 1fr;
    font-size: 16px;
  }

  .cart-item-heading {
    margin-bottom: 45px;
    text-align: center;
  }

  .cart-items-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 15px 0;
  }

  .cart-items-item img {
    width: 250px;
    margin: 10px 50px 0px 0px;
  }

  .info {
    width: 50%;
    font-size: 16px;
  }

  .details {
    width: 90%;
    font-size: 16px;
  }

  .btn {
    margin-top: 15px;
    font-size: 16px;
    padding: 12px;
    border: 3px solid green;
  }

  .icons {
    font-size: 26px;
  }

  .btn-remove {
    font-size: 28px;
  }

  .review-score {
    display: inline-block;
    margin-top: 5px;
  }
} */

.main-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f1f3f7; */
}
.cart {
  max-width: 100vw;
}

.cart-items-title {
  display: grid;
  grid-template-columns: 300px 500px 100px;
  /* grid-template-columns: 1.3fr 2fr 0.5fr; */
  color: rgb(18, 17, 17);
  font-size: 20px;
}

.cart-item-heading {
  padding: 30px;
}

.info {
  font-size: 16px;
  font-weight: 600;
}

.read {
  color: rgb(75, 124, 239);
}

.details {
  margin: 5px;
  width: 455px;
}
.btn {
  border: 3px solid green;
}
.btn:hover {
  border: 3px solid rgb(195, 232, 195);
}
.btn-more {
  margin-top: 15px;
  font-weight: 600;
  border-radius: 20px;
  border: 3px solid green;
}

.btn-more :hover {
  border-radius: 20px;
  border: 3px solid rgb(195, 232, 195);
}

.review-score {
  margin-left: 5px;
  color: green;
}

.icons {
  padding-left: 10px;
  font-size: 27px;
  margin-top: 9px;
}

.btn-remove {
  padding: 10px;
  margin-top: 50px;
  margin-left: 20px;
  border: none;
  background: transparent;
  font-size: 30px;
  color: red;
}

.cart-items-item {
  margin: 15px 0px 100px 0px;
  color: black;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: white;
  padding: 30px;
  cursor: pointer;
}
.card-heading {
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px 20px 0px 30px;
  background-color: white;
  font-weight: 500;
}

.cart-items-item img {
  width: 250px;
  border-radius: 12px;
}

/* Mobile styles */
@media (min-width: 10px) and (max-width: 576px) {
  .main-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f1f3f7; */
  }
  .cart {
    max-width: 90vw;
  }

  .cart-items-title {
    display: none;
  }

  .cart-item-heading {
    /* margin-bottom: 30px; */
    text-align: center;
  }

  .cart-items-item {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    margin: 15px 0px 100px 0px;
    position: relative;
    padding: 20px;
  }

  .cart-items-item img {
    width: 100%;
  }

  .info {
    font-size: 14px;
    margin-top: 20px;
  }

  .details {
    width: 100%;
    font-size: 14px;
  }

  .btn-more {
    margin-top: 10px;
    font-size: 14px;
    padding: 10px;
    border: 2px solid green;
    width: 90%;
  }

  .icons {
    font-size: 20px;
    margin: 5px;
  }

  .btn-remove {
    position: absolute;
    top: -30px;
    right: 25px;
    font-size: 23px;
    padding: 5px;
    border: none;
    background-color: transparent;
  }

  .review-score {
    margin-top: 5px;
  }
}

/* Tablet styles */
@media (min-width: 577px) and (max-width: 768px) {
  .main-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f1f3f7; */
  }
  .cart {
    max-width: 95vw;
  }

  .cart-items-title {
    grid-template-columns: 260px 320px 100px;
    /* grid-template-columns: 1.6fr 2fr 0.5fr; */
    font-size: 15px;
  }

  .cart-item-heading {
    /* margin-bottom: 40px; */
    text-align: center;
  }

  .cart-items-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 15px 0px 100px 0px;
  }

  .cart-items-item img {
    width: 230px;
    margin-right: 15px;
  }

  .info {
    width: 50%;
    font-size: 16px;
    padding-left: 20px;
  }

  .details {
    width: 90%;
    font-size: 13px;
  }

  .btn-more {
    margin-top: 12px;
    font-size: 15px;
    padding: 10px;
    border: 2px solid green;
  }

  .icons {
    font-size: 20px;
  }

  .btn-remove {
    font-size: 26px;
  }

  .review-score {
    display: inline-block;
  }
}

/* Laptop styles */
@media (min-width: 769px) and (max-width: 1025px) {
  .main-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f1f3f7; */
  }
  .cart {
    max-width: 90vw;
  }

  .cart-items-title {
    grid-template-columns: 300px 460px 100px;
    /* grid-template-columns: 1.7fr 2.7fr 0.5fr; */
    font-size: 16px;
  }

  .cart-item-heading {
    /* margin-bottom: 45px; */
    text-align: center;
  }

  .cart-items-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 15px 0px 100px 0px;
  }

  .cart-items-item img {
    width: 250px;
    margin: 10px 50px 0px 0px;
  }

  .info {
    width: 55%;
    font-size: 16px;
  }

  .details {
    width: 90%;
    font-size: 16px;
  }

  .btn-more {
    margin-top: 15px;
    font-size: 16px;
    padding: 12px;
    border: 3px solid green;
  }

  .icons {
    font-size: 26px;
  }

  .btn-remove {
    font-size: 28px;
    padding: 0px;
  }

  .review-score {
    display: inline-block;
    margin-top: 5px;
  }
}
